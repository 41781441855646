<template>
  <div>
    <v-container class="mt-5 mb-15 trainings-header">
      <v-row class="justify-start">
        <v-col cols="12" sm="6" md="6" lg="3">
          <v-card class="trainings-header__new"
                  :disabled="credit < 0 && usedMinutes > minutesToSpend">
            <v-dialog
              v-model="dialog"
              max-width="800px"
              @keydown.esc="closeDialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="new" @click="resetTraining()"
                       >
                  <v-icon left>mdi-plus-circle-outline</v-icon>
                  <div class="btn-text">New Training</div>
                </v-btn>
              </template>
              <v-card>
                  <template v-if="loadingFileListing">
                    <div class="overlay-loading"></div>
                    <div class="icon-loading">
                      <v-progress-circular
                        indeterminate
                        size="25"
                        width="2"
                        color="primary"
                      ></v-progress-circular>
                    </div>
                  </template>
                <v-form
                  @submit.prevent="onSaveItem"
                  ref="form"
                  class="trainingform"
                >
                  <v-toolbar dense flat>
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row class="justify-start mt-5">
                      <v-col cols="10">
                        <h3>
                          Basic Information<v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="tooltip"
                                right
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle
                              </v-icon>
                            </template>
                            <span>
                              Here you assign a meaningful title to the training
                              and, if necessary,
                              <br />
                              a more detailed description of the function, e.g.
                              which features are<br />
                              being trained, which component is involved, etc.
                              Finally, you can<br />
                              assign an internal identifier, e.g. your own
                              product number, <br />
                              a company-internal project-specific designation or
                              similar.
                            </span>
                          </v-tooltip>
                        </h3>
                      </v-col>
                    </v-row>
                    <v-text-field
                      label="Title"
                      v-model="editedItem.title"
                      :rules="[rules.minLength(1), rules.maxLength(128), rules.noSpecialCharacters()]"
                      class="mt-3"
                    ></v-text-field>
                    <form-error :errors="formErrors.title" />
                    <v-textarea
                      label="Description"
                      v-model="editedItem.description"
                      :rules="[rules.minLength(3), rules.maxLength(500)]"
                      rows="3"
                      class="mt-3"
                    ></v-textarea>
                    <form-error :errors="formErrors.description" />
                    <v-text-field
                      label="Internal identifier"
                      v-model="editedItem.internalIdentifier"
                      :rules="$rules.required"
                      class="mt-3"
                    ></v-text-field>
                    <form-error :errors="formErrors.internal_identifier" />
                    <v-row class="justify-start mt-5">
                      <v-col cols="10">
                        <h3>
                          Model Type<v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="tooltip"
                                right
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle
                              </v-icon>
                            </template>
                            <span>
                              Here you first select the training strategy with
                              which your data is to
                              <br />
                              be trained. The title describes the generated
                              model type that will<br />
                              later be used for the evaluation by the AI. A
                              description of which<br />
                              model type is suitable for which application is
                              displayed below <br />
                              the selection. Please note that the selection can
                              have a considerable
                              <br />influence on the duration and the costs of a
                              training.
                            </span>
                          </v-tooltip>
                        </h3>
                      </v-col>
                    </v-row>
                    <Models
                      v-model="selectedModel"
                      :selectedId="this.selectedModel.id"
                      :key="componentKeySelectedModel"
                      @input="calculateTrainingEstimate('model')"
                    />
                    <form-error :errors="formErrors.training_model_id" />
                    <v-row class="justify-start mt-5">
                      <v-col cols="10">
                        <h3>
                          Training Ressources<v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="tooltip"
                                right
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle
                              </v-icon>
                            </template>
                            <span>
                              Select the machine and the training folder for
                              which the training<br />
                              is to be started. Only machines that have
                              previously been created<br />
                              via the settings are available for selection
                              here.<br />
                              The training folder and its data must be located
                              in the File Manager<br />
                              under "ai-training" of the machine's data
                              structure.
                            </span>
                          </v-tooltip>
                        </h3>
                      </v-col>
                    </v-row>
                    <div>
                      <Machines
                        v-model="selectedMachine"
                        :selected="selectedMachine.id"
                        :key="componentKeySelectedMachine"
                      />
                      <form-error :errors="formErrors.machine_id" />
                    </div>
                    <v-select
                      v-if="editedItem.machineId > 0"
                      label="Select files (path)"
                      v-model="editedItem.datasetDirectory"
                      :items="currentDirListing"
                      itemText="name"
                      itemValue="name"
                      :rules="$rules.required"
                      @input="calculateTrainingEstimate('datasetDirectory')"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content class="notranslate" translate="no">
                          <v-list-item-title v-text="item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                    <form-error :errors="formErrors.dataset_directory" />
                    <v-row class="justify-start mt-5">
                      <v-col cols="10">
                        <h3>Training Parameters</h3>
                      </v-col>
                    </v-row>
                    <div class="trainingform__parameters">
                      <v-row class="justify-start">
                        <v-col cols="4">
                          <h4>
                            Training steps
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="tooltip"
                                  right
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-help-circle
                                </v-icon>
                              </template>
                              <span>
                                Here you set the number of iterations for a
                                training.<br />
                                The number of training steps should not be less
                                than<br />
                                the number of training images. This is the only
                                way<br />
                                to ensure that all the images are included in a
                                training cycle.
                              </span>
                            </v-tooltip>
                          </h4>
                        </v-col>
                        <v-col cols="4">
                          <v-btn
                            @click="setTrainingSteps"
                            class="btn btn-secondary"
                          >
                            {{ this.selectedModel.steps }}
                            (default)</v-btn
                          >
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            label="Free range (1000 - 100.000)"
                            v-model="editedItem.steps"
                            :rules="[rules.range(1000, 100000)]"
                            @input="calculateTrainingEstimate('steps')"
                          ></v-text-field>
                          <form-error :errors="formErrors.steps" />
                        </v-col>
                      </v-row>
                      <v-row class="justify-start">
                        <v-col cols="4">
                          <h4>
                            Learning rate
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="tooltip"
                                  right
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-help-circle
                                </v-icon>
                              </template>
                              <span>
                                The learning rate should initially be left at
                                the default value.<br />
                                A gradual increase should be made to a maximum
                                of twice the value.
                              </span>
                            </v-tooltip>
                          </h4>
                        </v-col>
                        <v-col cols="4">
                          <v-select
                            v-model="editedItem.learningRate"
                            :items="learningRateSteps"
                            item-text="text"
                            item-value="value"
                            label="Select learning rate"
                            @change="setLearningRate($event)"
                          ></v-select>
                          <form-error :errors="formErrors.learning_rate" />
                        </v-col>
                      </v-row>
                      <v-row class="justify-start">
                        <v-col cols="4">
                          <h4>
                            Augmentations
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="tooltip"
                                  right
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-help-circle
                                </v-icon>
                              </template>
                              <span>
                                To create additional variants of the training
                                images, additional<br />
                                filters can be used. Each filter is then
                                randomly applied to <br />
                                a certain number of the training images.<br />
                              </span>
                            </v-tooltip>
                          </h4>
                        </v-col>
                        <v-col cols="4">
                          <v-checkbox
                            v-model="editedItem.augmentations"
                            value="random_horizontal_flip"
                            :ripple="false"
                          >
                            <template v-slot:label>
                              {{
                                $t(
                                  "trainings.augmentations.random_horizontal_flip"
                                )
                              }}
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="tooltip"
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span
                                  >{{
                                    $t(
                                      "trainings.augmentations.random_horizontal_flip_info"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </template></v-checkbox
                          >
                          <v-checkbox
                            v-model="editedItem.augmentations"
                            value="random_vertical_flip"
                            :ripple="false"
                          >
                            <template v-slot:label>
                              {{
                                $t(
                                  "trainings.augmentations.random_vertical_flip"
                                )
                              }}
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="tooltip"
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span
                                  >{{
                                    $t(
                                      "trainings.augmentations.random_vertical_flip_info"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </template></v-checkbox
                          >
                          <v-checkbox
                            v-model="editedItem.augmentations"
                            value="random_rotation90"
                            :ripple="false"
                          >
                            <template v-slot:label>
                              {{
                                $t("trainings.augmentations.random_rotation90")
                              }}
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="tooltip"
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span
                                  >{{
                                    $t(
                                      "trainings.augmentations.random_rotation90"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </template></v-checkbox
                          >
                          <v-checkbox
                            v-model="editedItem.augmentations"
                            value="random_pixel_value_scale"
                            :ripple="false"
                          >
                            <template v-slot:label>
                              {{
                                $t(
                                  "trainings.augmentations.random_pixel_value_scale"
                                )
                              }}
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="tooltip"
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span
                                  >{{
                                    $t(
                                      "trainings.augmentations.random_pixel_value_scale_info"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </template></v-checkbox
                          >
                          <v-checkbox
                            v-model="editedItem.augmentations"
                            value="random_adjust_hue"
                            :ripple="false"
                          >
                            <template v-slot:label>
                              {{
                                $t("trainings.augmentations.random_adjust_hue")
                              }}
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="tooltip"
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span
                                  >{{
                                    $t(
                                      "trainings.augmentations.random_adjust_hue_info"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </template></v-checkbox
                          >
                        </v-col>
                        <v-col cols="4">
                          <v-checkbox
                            v-model="editedItem.augmentations"
                            value="random_adjust_saturation"
                            :ripple="false"
                          >
                            <template v-slot:label>
                              {{
                                $t(
                                  "trainings.augmentations.random_adjust_saturation"
                                )
                              }}
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="tooltip"
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span
                                  >{{
                                    $t(
                                      "trainings.augmentations.random_adjust_saturation_info"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </template></v-checkbox
                          >
                          <v-checkbox
                            v-model="editedItem.augmentations"
                            value="random_adjust_brightness"
                            :ripple="false"
                          >
                            <template v-slot:label>
                              {{
                                $t(
                                  "trainings.augmentations.random_adjust_brightness"
                                )
                              }}
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="tooltip"
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span
                                  >{{
                                    $t(
                                      "trainings.augmentations.random_adjust_brightness_info"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </template></v-checkbox
                          >
                          <v-checkbox
                            v-model="editedItem.augmentations"
                            value="random_adjust_contrast"
                            :ripple="false"
                          >
                            <template v-slot:label>
                              {{
                                $t(
                                  "trainings.augmentations.random_adjust_contrast"
                                )
                              }}
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="tooltip"
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span
                                  >{{
                                    $t(
                                      "trainings.augmentations.random_adjust_contrast_info"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </template></v-checkbox
                          >
                          <v-checkbox
                            v-model="editedItem.augmentations"
                            value="random_patch_gaussian"
                            :ripple="false"
                          >
                            <template v-slot:label>
                              {{
                                $t(
                                  "trainings.augmentations.random_patch_gaussian"
                                )
                              }}
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="tooltip"
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span
                                  >{{
                                    $t(
                                      "trainings.augmentations.random_patch_gaussian_info"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </template></v-checkbox
                          >
                          <v-checkbox
                            v-model="editedItem.augmentations"
                            value="random_jpeg_quality"
                            :ripple="false"
                          >
                            <template v-slot:label>
                              {{
                                $t(
                                  "trainings.augmentations.random_jpeg_quality"
                                )
                              }}
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    class="tooltip"
                                    right
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span
                                  >{{
                                    $t(
                                      "trainings.augmentations.random_jpeg_quality_info"
                                    )
                                  }}
                                </span>
                              </v-tooltip>
                            </template></v-checkbox
                          >
                        </v-col>
                        <form-error :errors="formErrors.augmentations" />
                      </v-row>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <div
                      v-if="estimatedTrainingTime"
                      class="estimated-time theme--light">
                      Estimated training time:&nbsp;
                      <template v-if="trainingCalculating">
                        <div class="training__calculation-spinner">
                          <v-icon class="mdi-loading">mdi-spin</v-icon>
                        </div>
                      </template>
                      <template v-else>
                        <strong>{{ estimatedTrainingTime }} minutes</strong>
                      </template>
                    </div>
                    <v-btn type="submit" class="btn btn-radius-corner">
                      {{ submitButtonTitle }}</v-btn
                    >
                    <v-btn
                      @click="closeDialog"
                      class="btn btn-gray btn-radius-corner"
                    >
                      Cancel</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="ps-sm-6">
          <v-card
            class="
              trainings-header__counter
              dashboard__card-placeholder
              v-card v-sheet
            "
          >
            <div class="label">Euro</div>
            <div class="value" v-if="credit === null">
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
              ></v-progress-circular>
            </div>
            <div class="value" v-else>{{ credit }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="ps-lg-6">
          <v-card
            class="
              trainings-header__counter
              dashboard__card-placeholder
              v-card v-sheet
            "
          >
            <div class="label">Minutes</div>
            <div class="value" v-if="usedMinutes === null">
                <v-progress-circular
                  indeterminate
                  size="20"
                  width="2"
                ></v-progress-circular>
              </div>
            <div class="value" v-else>{{ usedMinutes }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="ps-sm-6">
          <v-card
            class="
              trainings-header__counter
              dashboard__card-placeholder
              v-card v-sheet
            "
          >
            <div class="label">Completed</div>
            <div class="value" v-if="finishedTrainings === null">
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
              ></v-progress-circular>
            </div>
            <div class="value" v-else>{{ finishedTrainings }}</div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="content-header-section pt-15 pb-15">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <div class="d-flex justify-space-between">
            <div class="d-flex">
              <div class="mr-3">
                <v-select
                  clearable
                  v-model="selectedStatus"
                  :items="allStatus"
                  @change="onFilterChange"
                  item-text="name"
                  item-value="value"
                  label="Status"
                  dense
                  class="status-select"
                  v-if="trainings"
                  :disabled="loading"
                ></v-select>
              </div>
              <div class="mr-3">
                <v-select
                  clearable
                  v-model="filterSelectedMachine"
                  :items="machines"
                  @change="onFilterChange"
                  item-text="title"
                  item-value="id"
                  label="Machine"
                  dense
                  class="select-machine"
                  v-if="machines"
                  :disabled="loading"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content class="notranslate" translate="no">
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
              <div>
                <v-select
                  clearable
                  v-model="filterSelectedUser"
                  :items="users"
                  @change="onFilterChange"
                  :item-text="item => item.firstname + ' ' + item.lastname"
                  item-value="id"
                  label="User"
                  dense
                  class="select-user"
                  v-if="users"
                  :disabled="loading"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content class="notranslate" translate="no">
                      <v-list-item-title v-text="item.firstname + ' ' + item.lastname"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
            </div>
            <div>
              <v-btn
                small
                text
                @click="resetFilter()"
                :disabled="filterSelectedMachine === 'all' && selectedStatus === 'all'
                  && filterSelectedUser === 'all'"
              >Clear filters</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-container
        class="content-header-section pt-15 pb-15 text-center"
        v-if="loading"
      >
      <v-progress-circular
        indeterminate
        size="100"
        color="black"
      ></v-progress-circular>
    </v-container>
      <v-row v-else>
        <v-col cols="12" class="trainings">
          <v-expansion-panels multiple v-model="panel" v-if="trainings">
            <div
              class="trainings__item"
              v-for="(training, index) in trainings"
              :key="index"
            >
              <Training
                :training="training"
                :key="componentKeyTrainings"
                :id="index"
                :exceeded_resources="exceeded_resources"
                @start="onStart"
                @delete="onDelete"
                @cancel="onCancel"
                @edit="onEdit"
                @clone="onClone"
                @retry="retryValidation"
                @download="downloadResultTraining"
              />
            </div>
          </v-expansion-panels>
          <div  v-if="trainings.length === 0 && loading === false"
            class="col-2 mx-auto">
            <span>{{ $t('common.no-data-available') }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="content-header-section pt-0 pb-15">
      <v-pagination
        v-if="totalPages > 1"
        v-model="page"
        :length="totalPages"
        :total-visible="7"
        class="justify-center"
        @input="closeAllPanels"
      ></v-pagination>
    </v-container>
    <dialog-confirm  ref="confirm" />
  </div>
</template>

<script>
import axios from 'axios';
import getTrainings from '@/api/user/getTrainings';
import validateDataset from '@/api/user/validateDataset';
import abortTrainings from '@/api/user/abortTraining';
import deleteTrainings from '@/api/user/deleteTraining';
import createTraining from '@/api/user/createTraining';
import editTraining from '@/api/user/editTraining';
import folderList from '@/api/user/folderList';
import getAccountBalance from '@/api/customer/getAccountBalance';
import calculateMinutes from '@/api/user/calculateMinutes';
import DialogConfirm from '@/components/DialogConfirm.vue';
import startTraining from '@/api/user/startTraining';
import getModels from '@/api/admin/settings/getModels';
import FormErrorMixin from '@/mixins/FormErrorMixin';
import FormError from '@/components/_common/FormError.vue';
import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin';
import getCountImages from '@/api/filemanager/getCountImages';
import getMachines from '@/api/user/getMachines';
import Training from './Training.vue';
import Models from './new/Models.vue';
import Machines from './new/Machines.vue';

export default {
  name: 'Trainings',
  components: {
    Training,
    DialogConfirm,
    Models,
    Machines,
    FormError,
  },
  mixins: [FormErrorMixin, FormValidationRulesMixin],
  computed: {
    isEditing() {
      return this.editedIndex !== -1;
    },
    dialogTitle() {
      return this.editedIndex === -1 ? 'New Training' : 'Edit Training';
    },
    submitButtonTitle() {
      return this.editedIndex === -1 ? 'Create Training' : 'Update Training';
    },
  },
  data() {
    return {
      loading: true,
      page: 1,
      totalItems: 0,
      totalPages: 1,
      itemsPerPage: 30,
      interval: '',
      intervalUser: '',
      dialog: false,
      editedIndex: -1,
      learningRateSteps: [
        { text: 'Very low (0.0001)', value: 0.0001 },
        { text: 'Low (0.0005)', value: 0.0005 },
        { text: 'Medium (0.001)', value: 0.001 },
        { text: 'High (0.005)', value: 0.005 },
        { text: 'Very high (0.01)', value: 0.01 },
      ],
      editedItem: {
        title: null,
        description: '',
        internalIdentifier: null,
        steps: 0,
        learningRate: 0.001,
        datasetDirectory: '',
        trainingModelId: 0,
        machineId: 0,
        augmentations: [],
        countOfImages: 1,
      },
      defaultItem: {
        title: null,
        description: '',
        internalIdentifier: null,
        steps: 0,
        learningRate: 0.001,
        learningRateSteps: [
          { text: 'Very low (0.0001)', value: 0.0001 },
          { text: 'Low (0.0005)', value: 0.0005 },
          { text: 'Medium (0.001)', value: 0.001 },
          { text: 'High (0.005)', value: 0.005 },
          { text: 'Very high (0.01)', value: 0.01 },
        ],
        datasetDirectory: '',
        trainingModelId: 0,
        machineId: 0,
        augmentations: [],
        countOfImages: 1,
      },
      componentKeyTrainings: 0,
      componentKeySelectedModel: 1000,
      componentKeySelectedMachine: 2000,
      currentCompany: null,
      loadingUser: true,
      trainings: [],
      credit: null,
      usedMinutes: null,
      minutesToSpend: null,
      finishedTrainings: null,
      exceeded_resources: false,
      selectedModel: {
        id: 2,
        steps: 0,
        learning_rate: 0.001,
      },
      selectedMachine: {},
      loadingFileListing: false,
      currentDirListing: [],
      bearer: '',
      apiUrl: process.env.VUE_APP_ROOT_API
        ? process.env.VUE_APP_ROOT_API.trim().replace(/\/+$/, '')
        : '',
      panel: [],
      date: new Date(),
      estimatedTrainingTime: null,
      trainingOverOneDay: false,
      trainingCalculating: false,
      networkConstants: {
        fast: 0.0033333,
        balanced: 0.0033333,
        precise: 0.0108,
        segmentation: 0.015,
      },
      selectedStatus: 'all',
      allStatus: [
        { name: 'Aborted', value: 'aborted' },
        { name: 'Created', value: 'created' },
        { name: 'Running', value: 'running' },
        { name: 'Finished', value: 'completed' },
      ],
      machines: [],
      filterSelectedMachine: 'all',
      users: [],
      filterSelectedUser: 'all',
    };
  },
  created() {
    this.getMachines();
    this.fetchTrainings();
    this.calcFinishedTrainings();
    const bearer = this.$store.getters['user/token'];
    this.bearer = bearer.replace('bearer ', '');
    this.fetchUsedMinutes();
  },
  mounted() {
    this.interval = setInterval(() => {
      this.fetchTrainings();
      this.calcFinishedTrainings();
    }, 120000);
    this.getCredit();
    this.intervalUser = setInterval(() => {
      this.checkUserApiRequestDone();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.intervalUser);
  },
  methods: {
    async getCredit() {
      const creditRes = await getAccountBalance();
      this.credit = creditRes.data.account_balance;
    },
    forceRerenderTrainings() {
      this.componentKeyTrainings += 1;
    },
    resetTraining() {
      // reset training model data
      this.selectedModel = {
        id: 2,
        steps: 8000,
        learning_rate: 0.001,
      };
      this.componentKeySelectedModel = 1000;
    },
    async getMachines() {
      await getMachines()
        .then((response) => {
          this.machines = response.data;
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.loadingFileListing = false;
        });
    },
    fetchTrainings() {
      this.loading = true;
      const start = this.itemsPerPage * (this.page - 1);
      const state = this.selectedStatus === 'all' ? undefined : this.selectedStatus;
      const machineFilter = this.filterSelectedMachine === 'all' ? undefined : this.filterSelectedMachine;
      const userFilter = this.filterSelectedUser === 'all' ? undefined : this.filterSelectedUser;
      getTrainings(start, state, machineFilter, userFilter)
        .then((response) => {
          this.trainings = response.data.data;
          this.totalItems = response.data.total;
          this.totalPages = Math.ceil(response.data.total / this.itemsPerPage);
          this.users = response.data.creators;
        })
        .finally(() => {
          this.loading = false;
          this.forceRerenderTrainings();
        });
    },
    fetchFoldersOfMachine(machineId) {
      this.loadingFileListing = true;
      folderList(machineId)
        .then((response) => {
          this.currentDirListing = response.data;
          this.loadingFileListing = false;
        })
        .finally(() => {
          this.loadingFileListing = false;
        });
    },
    calcFinishedTrainings() {
      const filter = 'completed';
      getTrainings(0, filter)
        .then((response) => {
          this.finishedTrainings = response.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onDelete(training) {
      this.$refs.confirm
        .open('Delete this training', 'Do you really want to delete this training?')
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch(
              'dialogloader/show',
              'The training is being deleted ...',
            );
            deleteTrainings(training).finally(() => {
              this.fetchTrainings();
              this.$store.dispatch('dialogloader/hide');
            });
          }
        });
    },
    onCancel(training) {
      const trainingTitle = training.title ? `with the title "${training.title}"` : '';
      this.$refs.confirm
        .open('Cancel this training', `Are you sure to cancel the ongoing training ${trainingTitle} ?`)
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch(
              'dialogloader/show',
              'The training is being canceled ...',
            );
            abortTrainings(training).finally(() => {
              this.fetchTrainings();
              setTimeout(() => {
                this.$store.dispatch('dialogloader/hide');
              }, 1000);
            });
          }
        });
    },
    retryValidation(id) {
      this.$refs.confirm
        .open(
          'Retry Validation this training',
          'Retry Validation on this training?',
        )
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', 'Retry validationing...');
            validateDataset('training', id)
              .then(() => {
                this.fetchTrainings();
              })
              .finally(() => {
                this.$store.dispatch('dialogloader/hide');
              });
          }
        });
    },
    async getCountOfImages(machineId, datasetDirectory) {
      try {
        const response = await getCountImages(machineId, datasetDirectory);
        console.log('getCountOfImages response: ', response);
        return parseInt(response.data, 10);
      } catch (error) {
        console.log('[getCountOfImages error: ', error);
        return 1;
      }
    },
    onEdit(training, index) {
      // prepare data for edit
      this.editedItem = { ...training };
      this.editedItem.datasetDirectory = training.dataset_directory;
      this.editedItem.internalIdentifier = training.internal_identifier;
      this.editedIndex = index;
      this.editedItem.machineId = training.machine.id;
      this.editedItem.trainingModelId = this.selectedModel.id;
      this.editedItem.learningRate = training.learning_rate;
      // eslint-disable-next-line max-len
      const selectedLearningRate = this.learningRateSteps.findIndex((option) => option.value === training.learning_rate);
      this.editedItem.learningRate = this.learningRateSteps[selectedLearningRate].value;
      this.editedItem.learningRateSteps = this.learningRateSteps;
      this.getCountOfImages(training.machine.id, training.dataset_directory).then((countImages) => {
        this.editedItem.countOfImages = countImages;
      });
      this.selectedModel = {
        id: training.training_model.id,
        steps: training.steps,
        learningRate: training.learning_rate,
      };
      this.selectedMachine.id = training.machine.id;
      this.fetchFoldersOfMachine(this.selectedMachine.id);
      this.componentKeySelectedModel += 1;
      this.componentKeySelectedMachine += 1;
      this.calculateTrainingEstimate('edit');
      this.dialog = true;
    },
    onClone(training) {
      this.$refs.confirm
        .open('Clone this training', 'Confirm cloning this training with current parameters?')
        .then((confirm) => {
          if (confirm) {
            this.selectedModel = {
              id: training.training_model.id,
              steps: training.steps,
              learningRate: training.learning_rate,
            };
            this.editedIndex = -1;
            this.editedItem = { ...training };
            this.editedItem.datasetDirectory = training.dataset_directory;
            this.editedItem.internalIdentifier = training.internal_identifier;
            this.editedItem.machineId = training.machine.id;
            this.editedItem.trainingModelId = this.selectedModel.id;
            this.editedItem.learningRate = training.learning_rate;
            this.selectedMachine.id = training.machine.id;
            this.fetchFoldersOfMachine(this.selectedMachine.id);
            this.componentKeySelectedModel += 1;
            this.componentKeySelectedMachine += 1;
            this.calculateTrainingEstimate('clone');
            this.dialog = true;
          }
        });
    },
    onStart(training) {
      this.$refs.confirm
        .open('Start this training', `Start training "${training.title}"?`)
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch(
              'dialogloader/show',
              'The training is being started ...',
            );
            startTraining(training).finally(() => {
              this.fetchTrainings();
              this.$store.dispatch('dialogloader/hide');
            });
          }
        });
    },
    setTrainingSteps() {
      this.editedItem.steps = this.selectedModel.steps;
      this.calculateTrainingEstimate('steps');
    },
    setLearningRate(selectedValue) {
      this.editedItem.learningRate = selectedValue;
    },
    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
      this.formErrors = {};
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.selectedMachine = {};
      this.componentKeySelectedModel += 1;
      this.componentKeySelectedMachine += 1;
      this.trainingOverOneDay = false;
    },
    onSaveItem() {
      if (!this.$refs.form.validate()) return;
      if (this.isEditing) {
        this.$store.dispatch('dialogloader/show', 'Editing in progress...');
        editTraining(this.editedItem)
          .then(() => {
            this.closeDialog();
            this.fetchTrainings();
          })
          .finally(() => this.$store.dispatch('dialogloader/hide'));
      } else if (this.trainingOverOneDay === true) {
        this.$refs.confirm.open('Confirm training creation', 'Your training has a estimated time of over 1 day')
          .then((confirm) => {
            if (confirm) {
              this.$store.dispatch('dialogloader/show', 'Adding in progress...');
              createTraining(this.editedItem)
                .then(() => {
                  this.fetchTrainings();
                  this.closeDialog();
                })
                .finally(() => this.$store.dispatch('dialogloader/hide'));
            }
          });
      } else {
        this.$store.dispatch('dialogloader/show', 'Adding in progress...');
        createTraining(this.editedItem)
          .then(() => {
            this.fetchTrainings();
            this.closeDialog();
          })
          .finally(() => this.$store.dispatch('dialogloader/hide'));
      }
    },
    fetchModels() {
      getModels()
        .then((response) => {
          this.data = response.data;
          this.totalItems = response.data.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkUserApiRequestDone() {
      this.currentCompany = this.$store.getters['user/company'];
      if (this.currentCompany) {
        if (this.currentCompany.pricing !== null) {
          this.minutesToSpend = this.currentCompany.pricing.training_minutes
          + this.currentCompany.pricing.more_minutes;
        }
        if (this.credit < 0 && (this.usedMinutes > this.minutesToSpend)) {
          this.exceeded_resources = true;
        } else {
          this.exceeded_resources = false;
        }
      }
    },
    downloadResultTraining(training) {
      this.$store.dispatch('dialogloader/show', 'Downloading in progress...');
      const url = `${this.apiUrl}/api/v1/training/download-result/get-presigned-url/${training.id}`;
      const config = {
        headers: {
          responseType: 'json',
          Authorization: `Bearer ${this.bearer}`,
        },
      };
      axios
        .get(url, config)
        .then((response) => {
          const presignedUrl = response.data.presigned_url;
          const link = document.createElement('a');
          const disposition = response.headers['content-disposition'];
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          let filename = 'download';
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
          link.href = presignedUrl;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => console.log('ERROR: downloadResultTraining', error));
      this.$store.dispatch('dialogloader/hide');
    },
    fetchUsedMinutes() {
      calculateMinutes(this.date.getMonth() + 1, this.date.getFullYear())
        .then((response) => {
          this.usedMinutes = response.data.minutes;
        });
    },
    async calculateTrainingEstimate(updatedField) {
      this.trainingCalculating = true;
      const { machineId, datasetDirectory } = this.editedItem;
      const initTime = 2; // in minutes
      const codeLoadingTime = 3; // in minutes
      const downloadTimeIntern = 6; // in minutes
      const fileConstant = 0.004;
      const networkConstant = this.networkConstants[this.selectedModel.name];
      let imageCount = this.editedItem.countOfImages;

      if (machineId !== 0 && datasetDirectory && updatedField !== 'steps') {
        try {
          const response = await getCountImages(machineId, datasetDirectory);
          imageCount = parseInt(response.data, 10) || imageCount;
          this.editedItem.countOfImages = imageCount;
        } catch (error) {
          console.error(error);
        }
      }

      const recordTime = imageCount * fileConstant; // in minutes
      const training = networkConstant * this.editedItem.steps; // in minutes
      // eslint-disable-next-line max-len
      const estimatedTrainingInMinutes = initTime + recordTime + codeLoadingTime + training + downloadTimeIntern;
      this.estimatedTrainingTime = parseFloat(estimatedTrainingInMinutes.toFixed(0));
      this.trainingCalculating = false;
      this.trainingOverOneDay = this.estimatedTrainingTime > 1440;
    },
    closeAllPanels() {
      this.panel = [];
    },
    resetFilter() {
      this.selectedStatus = 'all';
      this.filterSelectedMachine = 'all';
      this.filterSelectedUser = 'all';
      this.fetchTrainings();
    },
    onFilterChange() {
      this.page = 1;
      this.fetchTrainings();
    },
  },
  watch: {
    selectedModel: {
      deep: false,
      handler() {
        if (this.selectedModel.id !== this.editedItem.trainingModelId) {
          this.editedItem.trainingModelId = this.selectedModel.id;
          this.editedItem.steps = this.selectedModel.steps;
          this.editedItem.learningRate = this.selectedModel.learning_rate;
          if (this.editedItem.training_model) {
            this.editedItem.training_model.id = this.selectedModel.id;
            this.editedItem.training_model.name = this.selectedModel.name;
          }
        }
      },
    },
    dialog(newValue) {
      if (newValue === false) {
        this.closeDialog();
      }
    },
    selectedMachine: {
      deep: false,
      handler() {
        if (
          this.selectedMachine !== undefined
          && this.selectedMachine.id !== undefined
          && this.selectedMachine.id !== this.editedItem.machineId
        ) {
          this.editedItem.machineId = this.selectedMachine.id;
          if (this.editedIndex === -1) {
            this.editedItem.datasetDirectory = '';
          }
          this.fetchFoldersOfMachine(this.selectedMachine.id);
        }
      },
    },
    page: {
      deep: false,
      handler() {
        this.fetchTrainings();
      },
    },
  },
};
</script>
