function createTestingReportSheet(testing) {
  const { id } = testing.testing;
  return window.$http({
    url: `/api/v1/testing/${id}/report-sheet`,
    method: 'post',
    headers: { Accept: 'application/pdf' },
    params: {
      download: true,
    },
  });
}

export default createTestingReportSheet;
