<template>
  <v-row class="ma-0">
    <v-expansion-panel>
      <v-col class="testing py-0" cols="12" :data-id="testing.testing.id"
       :class="testing.testing.id">
        <v-row class="">
          <v-col class="testing__firstcol pl-0" cols="12" sm="12" md="8" lg="8" xl="5">
            <v-row class="">
              <v-col class="py-0" cols="12">
                <div class="testing__date">
                  {{ format_date(testing.testing.created_at) }}
                </div>
                <div class="testing__title notranslate" translate="no">
                  <div v-if="!testing.testing.title">unknown</div>
                  <div v-else>{{ testing.testing.title }}</div>
                </div>
                <div class="testing__creator">
                  {{ testing.testing.company_user.user.firstname }}
                  {{ testing.testing.company_user.user.lastname }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="testing__quality-indicator pt-0 pb-0 pr-7 pl-7" cols="12" sm="12" md="4" lg="4" xl="2">
            <Plotly v-if="plotlyDataLoaded"
              :data="statisticsFiguresPlotlyData"
              :layout="smallIndicatorPlotlyLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
          <v-col class="" cols="12" sm="7" md="7" lg="7" xl="3">
            <v-row class="testing__actions">
              <v-col md="4" sm="4">
                <div class="dataset"
                  v-if="checkErrorsWarnings(testing.testing.errors_and_warnings)
                    || testing.testing.state === 'defective'"
                  :class="{
                    'warning-dataset': (testing.testing.state === 'created' &&
                    testing.testing.errors_and_warnings.warnings ?
                    testing.testing.errors_and_warnings.warnings.length : 0 )
                    || testing.testing.state === 'defective',
                    'error-dataset': testing.testing.errors_and_warnings.errors ?
                    testing.testing.errors_and_warnings.errors.length : 0
                  }">
                  <v-icon class="ml-1" size="20">
                    mdi-alert
                  </v-icon>
                  <span style="font-size: 12px; margin-left: 1px;" v-if="testing.testing.state !== 'error'">Dataset</span>
                </div>
                <div v-else-if="testing.testing.state !== 'not-validated'
                  && testing.testing.mode !== 'basic'
                  && testing.testing.data.dataset_score !== undefined
                  && testing.testing.data.dataset_score !== null">
                  <v-img :src="require(`@/assets/icons/dataset-validation/${getDatasetEvaluationResult.icon}`)" style="width: 70px; height: 100%;" alt=""></v-img>
                </div>
              </v-col>
              <v-col md="6" sm="6">
                <v-btn class="validate-dataset-btn" small @click="retryValidation(testing)"
                  v-if="testing.testing.state === 'not-validated'">
                  <v-icon>mdi-format-list-checks</v-icon>
                  Validate
                </v-btn>
                <v-btn class="start-testing-btn" small @click="onStart(testing)"
                      :disabled="testing.testing.state === 'defective' || exceeded_resources"
                      v-if="testing.testing.state === 'created'
                      || testing.testing.state === 'defective'">
                  <v-icon>mdi-play</v-icon>
                  Start
                </v-btn>
                <v-btn class="start-download-btn" small v-if="testing.testing.state === 'finished'
                  && testing.testing.is_exported === true" @click="onDownloadResultTesting(testing)"
                      color="#21BA45">
                  <v-icon>mdi-download</v-icon>
                  Download
                </v-btn>
                <div class="testing__info-spinner"
                    v-if="testing.testing.state === 'started'
                    && testing.testing.started_at === null">
                  <v-icon class="mdi-loading">mdi-spin</v-icon>
                  Initialising<br>Testing
                </div>
                <div class="testing__info-spinner ml-lg-15"
                  v-if="testing.testing.state === 'finished' &&
                  testing.testing.is_exported === false">
                  <v-icon class="mdi-loading">mdi-spin</v-icon>
                  Preparing<br>Download
                </div>
              </v-col>
              <v-col md="2" sm="2" class="pl-1 pr-1" style="display: flex;">
                <div>
                  <v-progress-circular
                    v-if="loadingReportSheetData"
                    color="primary"
                    indeterminate
                    size="22"
                    width="2"
                  ></v-progress-circular>
                </div>
                <div>
                  <a v-if="testing.testing.state === 'finished' && testing.testing.is_exported === true"
                    title="print"
                    @click="generatePDF(testing)"
                    :class="{ printDisabled: loadingReportSheetData }"
                  >
                    <v-icon center>mdi-printer</v-icon>
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="ma-0 pa-3 testing__status" cols="12" sm="5" md="5" lg="5" xl="2">
            <div class="testing__status--msg" v-bind:class="this.statusMsgClass(testing)"
            >
              {{ getTestingStatus }}
              <div class="testing__date" v-if="testing.testing.state === 'started'
                && testing.testing.started_at">
                {{ format_date(testing.testing.started_at) }}
              </div>
              <div class="testing__date" v-else-if="testing.testing.state === 'finished'">
                {{ format_date(testing.testing.finished_at) }}
              </div>
            </div>
            <v-menu offset-y min-width="200px" nudge-left="20px" left>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  :ripple="true"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card class="testing__dots-vertical">
                <v-list>
                  <template
                    v-for="(item, index) in actionTesting"
                  >
                    <v-list-item
                      :disabled="!item.state.includes(testing.testing.state)"
                      :title="item.name"
                      :ripple="true"
                      :key="item.key"
                      class="mt-1"
                    >
                      <v-list-item-content
                        @click="actionTestingFunc(item.key, testing, index)"
                      >
                        <v-list-item-title>
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                </v-list>
              </v-card>
            </v-menu>
            <v-expansion-panel-header class="accordion-btn">
            </v-expansion-panel-header>
          </v-col>
        </v-row>
      </v-col>
      <div v-if="testing.testing.state === 'running'" class="mt-3">
        <div v-if="testing.testing.state === 'running'"
             class="testing__progress--linear">
          <v-progress-linear
            :value="progressPercent"
            color="transparent"
            height="25"
          >
            <template v-slot:default="{ value }">
              <strong class="progess-percent">{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
          <v-progress-linear :value="progressPercent"
                             color="#21BA45"
                             height="6"
                             reverse
                             buffer-value="0"
                             absolute bottom stream
          />
        </div>
      </div>
      <v-expansion-panel-content>
        <v-row class="ma-0 pt-7">
          <v-col class="pb-3 ps-0" cols="12" sm="6">
            <v-row class="">
              <v-col class="pt-0" md="10" sm="10">
                {{ testing.testing.description }}
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Internal ID: </span>
                <span>
                  {{ testing.testing.internal_identifier }}
                </span>
                <hr class="mt-5"/>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Machine: </span>
                <span>{{ testing.training.machine_title }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Data directory:&nbsp;</span>
                <span class="notranslate" translate="no">
                  {{ testing.testing.dataset_directory }}
                </span>
              </v-col>
<!-- uncomment when BE is ready -->
<!--              <v-col class="" md="10" sm="10">-->
<!--                <span class="font-weight-bold">Number of files: </span>-->
<!--                <span>{{ testing.testing.count_of_files }}</span>-->
<!--              </v-col>-->
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Model (Training): </span>
                <span>
                  {{ testing.training.title }}
                </span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Testmode: </span>
                <span>{{ testing.testing.mode }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Creator: </span>
                <span>
                  {{ testing.testing.company_user.user.firstname }}
                  {{ testing.testing.company_user.user.lastname }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pb-0 mb-0" cols="12" sm="6">
            <v-row class="info-box">
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Status: </span>
                <span class="state" :class="testing.testing.state">
                    {{ getTestingStatus }}</span>
                <span v-if="testing.testing.state === 'not-validated'">, but not validated</span>
              </v-col>
              <v-col class="" md="10" sm="10"
                     v-if="testing.testing.state === 'running' ||
                     testing.testing.state === 'finished'">
                <span class="font-weight-bold">Elapsed time: </span>
                <span>{{ getElapsedTime }}</span>
              </v-col>
              <v-col class="" md="10" sm="10" v-if="testing.testing.state === 'running'">
                <span class="font-weight-bold">Estimated time: </span>
                <span>{{ getEstimatedTime }}</span>
              </v-col>
              <v-col class="" md="10" sm="10"
                     v-if="testing.testing.state === 'running' ||
                     testing.testing.state === 'finished'">
                <span class="font-weight-bold">Starting date: </span>
                <span>{{ format_date(testing.testing.started_at, true, true) }}</span>
              </v-col>
              <v-col class="" md="10" sm="10" v-if="testing.testing.state === 'finished'">
                <span class="font-weight-bold">Finishing date: </span>
                <span>{{ format_date(testing.testing.finished_at, true, true) }}</span>
              </v-col>
              <v-col class="" cols="12">
                <div class="d-flex align-center mb-3" v-if="testing.testing.state !== 'not-validated'">
                  <span class="font-weight-bold mr-3">Dataset Validation: </span>
                  <span
                    class="d-flex align-center">
                    <span v-if="(testing.testing.errors_and_warnings.errors &&
                      testing.testing.errors_and_warnings.errors.length)
                      || testing.testing.state === 'defective'"
                          class="error-message mr-3">
                           fail
                    </span>
                    <span v-else class="ok-message mr-3">
                           ok
                    </span>
                    <v-btn
                      v-if="testing.testing.state === 'defective'
                      || testing.testing.state === 'created'"
                      class=""
                      small @click="retryValidation(testing)">
                              <v-icon>mdi-refresh</v-icon>
                              Retry
                    </v-btn>
                  </span>
                </div>
                <v-row v-if="testing.testing.errors_and_warnings.errors">
                  <v-col class="error-message pb-0" cols="12"
                         v-for="(error, index) in testing.testing.errors_and_warnings.errors"
                         :key="index">
                    <p>... {{ error.message }}</p>
                    <template v-if="error.data && error.data.length > 0">
                      <v-row
                        v-if="typeof error.data === 'string' || error.data instanceof String">
                        <v-col class="error-message py-0 pl-5" cols="12">
                          <v-icon
                            right
                            class="error-message"
                          >mdi-arrow-right-thin
                          </v-icon>
                          {{ error.data }}
                        </v-col>
                      </v-row>
                      <v-row class="pl-5"
                             v-else>
                        <v-col class="error-message py-0 pl-5" cols="12"
                               v-for="(dataItem, index) in error.data"
                               :key="index">
                          <v-icon
                            right
                            class="error-message"
                          >mdi-arrow-right-thin
                          </v-icon>
                          {{ dataItem }}
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>

                <v-row v-if="testing.testing.errors_and_warnings.warnings
                  && testing.testing.errors_and_warnings.warnings.length > 0">
                  <v-col class="warning-message pb-0" cols="12"
                         v-for="(warning, index) in testing.testing.errors_and_warnings.warnings"
                         :key="index">
                    <p>... {{ warning.message }}</p>
                    <v-row class="pl-5" v-if="warning.data && warning.data.length > 0">
                      <v-col class="warnings-message py-0 pl-5" cols="12"
                             v-for="(dataItem, index) in warning.data"
                             :key="index">
                        <v-icon
                          right
                          class="warnings-message"
                        >mdi-arrow-right-thin
                        </v-icon>
                        {{ dataItem }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="(testing.testing.errors_and_warnings.errors
                  && testing.testing.errors_and_warnings.errors.length === 0)
                  && (testing.testing.errors_and_warnings.warnings
                  && testing.testing.errors_and_warnings.warnings.length === 0)
                  && testing.testing.state === 'created'
                  && testing.testing.mode !== 'basic'
                  && testing.testing.data.dataset_score !== undefined
                  && testing.testing.data.dataset_score !== null">
                  <v-col cols="12">
                    <div>
                      <span class="font-weight-bold mr-1">Test Data Score: </span>
                      <span
                        :style="{'color': getDatasetEvaluationResult.color}">
                        {{ testing.testing.data.dataset_score.toFixed(2) }}
                      </span>
                    </div>
                    <div class="mt-3" v-if="getDatasetEvaluationResult !== datasetStatus.goodScore">
                      <v-icon
                        size="20"
                        :color="getDatasetEvaluationResult.color"
                        class="mr-1">
                        mdi-alert
                      </v-icon>
                      <span>
                        {{ getDatasetEvaluationResult.message }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="" cols="12">
                <hr class="mt-5" v-if="testing.testing.state === 'started'"/>
              </v-col>
              <v-col class="mt-auto p-2" md="10" sm="10"
                  v-if="testing.testing.state === 'started' ||
                  testing.testing.state === 'running'">
                  <v-btn class="btn-as-link"
                         @click="onCancel(testing.testing)">
                    cancel testing
                  </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="mt-4" style="margin-bottom: -13px;" cols="12" sm="12"
            v-if="testing.testing.state === 'finished'
            && testing.testing.mode !== 'basic'
            && testing.testing.statistics.length !== 0"
          >
            <v-row class="results-box pt-2 pb-7">
              <v-col class="charactersitics-table pt-5 pb-7" cols="12" sm="12" md="12" lg="6" xl="6">
                <Plotly v-if="plotlyDataLoaded"
                  :data="characteristicsTablePlotlyData"
                  :layout="characteristicsTablePlotlyLayout"
                  :display-mode-bar="false"
                  :staticPlot="true"
                >
                </Plotly>
              </v-col>
              <v-col class="statistics-charts pt-0 pb-3" cols="12" sm="12" md="12" lg="6" xl="6">
                <Plotly v-if="plotlyDataLoaded"
                  :data="statisticsFiguresPlotlyData"
                  :layout="statisticsFiguresPlotlyLayout"
                  :display-mode-bar="false"
                >
                </Plotly>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-row>
</template>

<script>

import moment from 'moment';
import { Plotly } from 'vue-plotly';
import createTestingReportSheet from '@/api/user/createTestingReportSheet';

export default {
  name: 'Testing',
  components: {
    Plotly,
  },
  props: {
    testing: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
    },
    exceeded_resources: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: 0,
      actionTesting: [
        { name: 'Clone', key: 'clone', state: ['created', 'defective', 'started', 'running', 'aborted', 'finished', 'error', 'failed', 'unknown', 'not-validated'] },
        { name: 'Edit', key: 'edit', state: ['created', 'defective', 'not-validated'] },
        { name: 'Cancel', key: 'cancel', state: ['started', 'running'] },
        { name: 'Delete', key: 'delete', state: ['created', 'defective', 'not-validated'] },
      ],
      evaluationConstants: {
        qualityLowerLimit: 50,
        qualityUpperLimit: 95,
        accuracyLowerLimit: 0.98,
        accuracyUpperLimit: 0.9973,
        sampleCountMeanLowerLimit: 10,
        sampleCountMeanUpperLimit: 50,
        sampleDeviationLowerLimit: 60,
        sampleDeviationUpperLimit: 20,
        secondClassDistanceMeanLowerLimit: 20,
        secondClassDistanceMeanUpperLimit: 80,
      },
      colorConstants: {
        bgColor: 'white',
        goodColor: 'limegreen',
        mediumColor: 'orange',
        badColor: 'red',
      },
      characteristicsTablePlotlyLayout: {
        width: 350,
        height: 416,
        margin: {
          t: 0, b: 0, l: 20, r: 20,
        },
        grid: {
          rows: 1,
          columns: 1,
        },
      },
      statisticsFiguresPlotlyLayout: {
        width: 350,
        height: 443,
        margin: {
          t: 50, b: 20, l: 40, r: 40,
        },
        grid: {
          rows: 2,
          columns: 1,
          pattern: 'coupled',
          roworder: 'bottom to top',
        },
        legend: { x: 0.63, y: 0.09 },
        uniformtext: { minsize: 14, mode: 'hide' },
      },
      smallIndicatorPlotlyLayout: {
        width: 180,
        height: 85,
        margin: {
          t: 17, b: 11, l: 10, r: 10,
        },
      },
      plotlyDataLoaded: false,
      characteristicsTablePlotlyData: [],
      statisticsFiguresPlotlyData: [],
      loadingReportSheetData: false,
      datasetStatus: {
        badScore: {
          icon: 'Signal-red.svg',
          message: 'Warning! Either the amount of images per type (class/pattern/etc.) is very low or the type distribution is very unequal. To receive a robust evaluation try to upload at least 50 images per type and try to keep the amount of samples per type in balance.',
          color: 'red',
        },
        mediumScore: {
          icon: 'Signal-yellow.svg',
          message: 'Warning! Either the amount of images per type (class/pattern/etc.) is low or the type distribution is unequal. To receive a robust evaluation try to upload at least 50 images per type and try to keep the amount of samples per type in balance.',
          color: '#facb13',
        },
        goodScore: {
          icon: 'Signal-green.svg',
          message: '',
          color: 'limegreen',
        },
      },
    };
  },
  mounted() {
    if (this.testing.job && this.testing.job.progress) {
      this.value = `${Math.round((this.testing.progress) * 1000) / 10}`;
    }
    if (this.testing.testing.state === 'finished' && this.testing.testing.mode !== 'basic' && this.testing.testing.statistics.length !== 0) {
      this.loadPlotlyTableData()
        .then(() => {
          this.loadPlotlyFiguresData();
        })
        .finally(() => {
          this.plotlyDataLoaded = true;
        });
    }
  },
  methods: {
    statusMsgClass(testing) {
      return `training__status--msg-${testing.testing.state}`;
    },
    checkErrorsWarnings(items) {
      return !!((items.errors && items.errors.length)
        || (items.warnings && items.warnings.length));
    },
    retryValidation(testing) {
      this.$emit('retry', testing.testing);
    },
    onDelete(testing) {
      this.$emit('delete', testing);
    },
    onCancel(testing) {
      this.$emit('cancel', testing);
    },
    onStart(testing) {
      this.$emit('start', testing);
    },
    onDownloadResultTesting(testing) {
      this.$emit('download', testing);
    },
    formatPercentFromFactor(factor) {
      return Math.ceil(factor * 100);
    },
    panelClicked() {
      this.$emit('panelClicked', this.id);
    },
    getDuration(d2, d1) {
      const diff = d1.getTime() - d2.getTime();
      return {
        getDays() {
          return Math.floor((diff / (1000 * 60 * 60 * 24)));
        },
        getHours() {
          return Math.floor(((diff - this.getDays() * 1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
        },
        getMinutes() {
          return Math.floor(((diff - (this.getDays() * 1000 * 60 * 60 * 24
            + this.getHours() * 1000 * 60 * 60))
            / (1000 * 60)));
        },
        toString() {
          const days = this.getDays() > 0 ? `${this.getDays()} days` : '';
          const hours = this.getHours() > 0 ? `${this.getHours()} hours` : '';
          const minutes = this.getMinutes() > 0 ? `${this.getMinutes()} minutes` : '';
          return `${days} ${hours} ${minutes}`;
        },
      };
    },
    actionTestingFunc(key, testing, index) {
      // eslint-disable-next-line
      const id = { id: testing.testing.id };

      switch (key) {
        case 'clone':
          this.$emit('clone', testing);
          break;
        case 'edit':
          this.$emit('edit', testing, index);
          break;
        case 'cancel':
          this.onCancel(testing.testing);
          break;
        case 'delete':
          this.onDelete(testing.testing);
          break;
        default:
      }
    },
    async loadPlotlyTableData() {
      let qualityColor = '';
      let accuracyColor = '';
      let countColor = '';
      let deviationColor = '';
      let distanceColor = '';
      let fontColorArray = [];
      let croppedConfusionArray = [];

      if (this.testing.testing.statistics.summary.testdata_score < this.evaluationConstants.qualityLowerLimit) {
        qualityColor = this.colorConstants.badColor;
      } else if (this.testing.testing.statistics.summary.testdata_score >= this.evaluationConstants.qualityLowerLimit && this.testing.testing.statistics.summary.testdata_score < this.evaluationConstants.qualityUpperLimit) {
        qualityColor = this.colorConstants.mediumColor;
      } else {
        qualityColor = this.colorConstants.goodColor;
      }
      if (this.testing.testing.statistics.summary.accuracy_overall < this.evaluationConstants.accuracyLowerLimit) {
        accuracyColor = this.colorConstants.badColor;
      } else if (this.testing.testing.statistics.summary.accuracy_overall >= this.evaluationConstants.accuracyLowerLimit && this.testing.testing.statistics.summary.accuracy_overall < this.evaluationConstants.accuracyUpperLimit) {
        accuracyColor = this.colorConstants.mediumColor;
      } else {
        accuracyColor = this.colorConstants.goodColor;
      }
      if (this.testing.testing.statistics.summary.average_count_per_type < this.evaluationConstants.sampleCountMeanLowerLimit) {
        countColor = this.colorConstants.badColor;
      } else if (this.testing.testing.statistics.summary.average_count_per_type >= this.evaluationConstants.sampleCountMeanLowerLimit && this.testing.testing.statistics.summary.average_count_per_type < this.evaluationConstants.sampleCountMeanUpperLimit) {
        countColor = this.colorConstants.mediumColor;
      } else {
        countColor = this.colorConstants.goodColor;
      }
      if (this.testing.testing.statistics.summary.deviation_relative_to_average > this.evaluationConstants.sampleDeviationLowerLimit) {
        deviationColor = this.colorConstants.badColor;
      } else if (this.testing.testing.statistics.summary.deviation_relative_to_average >= this.evaluationConstants.sampleDeviationUpperLimit && this.testing.testing.statistics.summary.deviation_relative_to_average < this.evaluationConstants.sampleDeviationLowerLimit) {
        deviationColor = this.colorConstants.mediumColor;
      } else {
        deviationColor = this.colorConstants.goodColor;
      }
      if (this.testing.testing.statistics.summary.second_class_distance_average < this.evaluationConstants.secondClassDistanceMeanLowerLimit) {
        distanceColor = this.colorConstants.badColor;
      } else if (this.testing.testing.statistics.summary.second_class_distance_average >= this.evaluationConstants.secondClassDistanceMeanLowerLimit && this.testing.testing.statistics.summary.second_class_distance_average < this.evaluationConstants.secondClassDistanceMeanUpperLimit) {
        distanceColor = this.colorConstants.mediumColor;
      } else {
        distanceColor = this.colorConstants.goodColor;
      }

      fontColorArray = [
        'black',
        [
          qualityColor,
          accuracyColor,
          countColor,
          deviationColor,
          distanceColor,
          'black',
        ],
      ];

      croppedConfusionArray = this.testing.testing.statistics.combined.main_confusion_histogram.slice(0, 4);

      const values = [
        [
          'Test Data Score'.split(),
          'Accuracy'.split(),
          'Mean Sample Count'.split(),
          'Sample Deviation'.split(),
          'Mean Class Distance'.split(),
          'Confidence Threshold'.split(),
          'Precision'.split(),
          'Recall'.split(),
          'F1Score'.split(),
          'True Positive'.split(),
          'False Positive'.split(),
          'True Negative'.split(),
          'False Negative'.split(),
        ],
        [
          this.testing.testing.statistics.summary.testdata_score.toFixed(2),
          `${(this.testing.testing.statistics.summary.accuracy_overall * 100).toFixed(3)} %`.split(),
          this.testing.testing.statistics.summary.average_count_per_type.toFixed(2),
          `${this.testing.testing.statistics.summary.deviation_relative_to_average.toFixed(2)} %`.split(),
          `${this.testing.testing.statistics.summary.second_class_distance_average.toFixed(2)} %`.split(),
          `${this.testing.testing.statistics.summary.used_confidence_threshold.toFixed(2)} %`.split(),
          `${(this.testing.testing.statistics.combined.main_precision * 100).toFixed(3)} %`.split(),
          `${(this.testing.testing.statistics.combined.main_recall * 100).toFixed(3)} %`.split(),
          `${(this.testing.testing.statistics.combined.main_f1score * 100).toFixed(3)} %`.split(),
          croppedConfusionArray[0],
          croppedConfusionArray[1],
          croppedConfusionArray[2],
          croppedConfusionArray[3],
        ],
      ];

      const data = [
        {
          type: 'table',
          columnorder: [1, 2],
          columnwidth: [68, 32],
          header: {
            values: [['Characteristics'.split()], ['']],
            align: ['left', 'left'],
            height: 38,
            line: { width: 1, color: 'white' },
            fill: { color: 'white' },
            font: { family: 'Roboto', size: 20, color: 'black' },
          },
          cells: {
            values,
            align: ['left', 'left'],
            height: 29,
            line: { width: 1, color: 'white' },
            fill: { color: this.colorConstants.bgColor },
            font: { family: 'Roboto', size: 16, color: fontColorArray },
          },
        },
      ];
      this.characteristicsTablePlotlyData = data;
    },
    async loadPlotlyFiguresData() {
      let gaugeColor = '';
      let croppedConfusionArray = [];
      croppedConfusionArray = this.testing.testing.statistics.combined.main_confusion_histogram.slice(0, 4);

      if (this.testing.testing.statistics.summary.model_score < this.evaluationConstants.qualityLowerLimit) {
        gaugeColor = this.colorConstants.badColor;
      } else if (this.testing.testing.statistics.summary.model_score >= this.evaluationConstants.qualityLowerLimit && this.testing.testing.statistics.summary.model_score < this.evaluationConstants.qualityUpperLimit) {
        gaugeColor = this.colorConstants.mediumColor;
      } else {
        gaugeColor = this.colorConstants.goodColor;
      }

      const captions = [
        'True Positive'.split(),
        'False Positive'.split(),
        'True Negative'.split(),
        'False Negative'.split(),
      ];

      const data = [
        {
          type: 'pie',
          name: 'Truth Value',
          title: {
            text: ' ',
            font: {
              color: 'black',
              family: 'Roboto',
              size: 20,
            },
          },
          labels: captions,
          values: croppedConfusionArray,
          textposition: 'inside',
          insidetextfont: { family: 'Roboto', size: 14 },
          hoverlabel: { font: { family: 'Roboto' } },
          domain: { row: 0, column: 0 },
          marker: {
            colors: ['limegreen', 'orange', '#3b8abf', 'tomato'],
          },
        },
        {
          type: 'indicator',
          mode: 'gauge+number',
          title: {
            text: 'Model Score',
            font: {
              color: 'black',
              family: 'Roboto',
              size: 20,
            },
          },
          domain: { row: 1, column: 0 },
          value: this.testing.testing.statistics.summary.model_score,
          gauge: {
            axis: {
              range: [0, 100],
              tickwidth: 1,
              tickcolor: 'white',
              tickfont: { family: 'Roboto' },
            },
            borderwidth: 2,
            bordercolor: 'white',
            bgcolor: 'whitesmoke',
            bar: { color: gaugeColor, thickness: 1 },
          },
          number: { font: { family: 'Roboto', size: 40, color: gaugeColor } },
        },
      ];
      this.statisticsFiguresPlotlyData = data;
    },
    async generatePDF(testing) {
      this.loadingReportSheetData = true;
      try {
        await createTestingReportSheet(testing);
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loadingReportSheetData = false;
      }
    },
  },
  computed: {
    progressPercent() {
      return Math.round((this.testing.testing.progress) * 1000) / 10;
    },
    getElapsedTime() {
      if (this.testing.testing.elapsed_minutes === 0) {
        return '-';
      }
      const remainingMinutes = this.testing.testing.elapsed_minutes % (24 * 60);
      const hours = Math.floor(remainingMinutes / 60);
      const minutes = Math.floor(remainingMinutes % 60);

      return `${hours > 0 ? `${hours} hour(s)` : ''} ${minutes} minute(s)`;
    },
    getEstimatedTime() {
      if (this.testing.testing.remaining_minutes_estimate > 0) {
        const d1 = moment.unix(0).local().toDate();
        const d2 = moment.unix(Math.round(this.testing.testing.remaining_minutes_estimate) * 60)
          .local().toDate();
        return `approx. ${this.getDuration(d1, d2).toString()}`;
      }
      if (
        this.testing.testing.remaining_minutes_estimate === 0
        || this.testing.testing.remaining_minutes_estimate === 0.0
      ) {
        return 'approx. 1 minute';
      }
      return '-';
    },
    getTestingStatus() {
      if (this.testing.testing.state === 'defective' || this.testing.testing.state === 'not-validated') {
        return 'Created';
      }
      if (this.testing.testing.state === 'created') {
        return 'Ready';
      }
      return this.testing.testing.state;
    },
    getDatasetEvaluationResult() {
      if (this.testing.testing.data.dataset_score < this.evaluationConstants.qualityLowerLimit) {
        return this.datasetStatus.badScore;
      }
      if (this.testing.testing.data.dataset_score >= this.evaluationConstants.qualityLowerLimit && this.testing.testing.data.dataset_score < this.evaluationConstants.qualityUpperLimit) {
        return this.datasetStatus.mediumScore;
      }
      return this.datasetStatus.goodScore;
    },
  },
};
</script>

<style lang="scss">
.v-list {
  .v-list-item {
    cursor: pointer;
  }
}
.charactersitics-table .plot-container.plotly {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  @media (max-width: 1263px) {
    justify-content: center;
    padding: 0;
  }
  .scrollbar-kit {
    display: none;
  }
  .column-block#header {
    cursor: default !important;
    .cell-text {
      font-weight: 500;
    }
  }
}
.statistics-charts .plot-container.plotly {
  display: flex;
  justify-content: flex-start;
  .main-svg:nth-of-type(1) {
    .pielayer .trace {
      g.titletext {
        font-weight: 500;
      }
    }
  }
  .main-svg:nth-of-type(2) {
    margin-left: 70px;
  }
  @media (max-width: 1263px) {
    justify-content: center;
    margin-left: -140px;
  }
}
.testing__quality-indicator .plot-container.plotly {
  display: flex;
  justify-content: flex-end;
  opacity: 1;
  transition: all 0.4s ease-in;
  .main-svg:nth-of-type(1) {
    display: none;
  }
  .main-svg:nth-of-type(2) {
    .infolayer {
      display: none;
    }
    .indicatorlayer .trace {
      text.title {
        display: none;
      }
      g.numbers text.number {
        font-size: 16px !important;
      }
      g.angularaxis .xangularaxistick {
        text {
          display: none;
        }
      }
    }
  }
  @media (max-width: 960px) {
    justify-content: center;
  }
}
.v-expansion-panel--active .testing__quality-indicator .plot-container.plotly {
  opacity: 0;
  transition: all 0.1s ease-out;
}
.printDisabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
