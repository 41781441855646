<template>
    <v-row class="justify-start" :class="{ disabled: isEditing === true }">
      <v-col class="justify-center" cols="3" v-for="(item, index) in itemsWithIconUrl"
             v-bind:key="index">
        <div class="testingform__modetype" v-on:click="activate(item)"
             :class="{ active : (active_el.id !== undefined && active_el.id === item.id), disabled: (!isEditing && hideMode(item.id)) }">
          <img :src="item.iconUrl" style="width: 50px; height: 50px"
               alt=""/>
          <div class="name">{{ item.name }}</div>
        </div>
      </v-col>
      <v-col cols="12" class="justify-start">
        <div class="trainingform__modeltype--descr">
          {{ this.active_el.description }}
        </div>
      </v-col>
    </v-row>
</template>
<script>

export default {
  name: 'TestingModes',
  data() {
    return {
      active_el: {},
      data: [],
      loading: true,
      defaultModelName: 'basic',
      modes: [
        {
          id: 1,
          name: 'Basic',
          description: 'Runs the basic test mode (with the model of the training selected below) on each image and writes the raw results in a csv file. No logical or statistical evaluations are applied.',
          icon: 'icon-testmode-basic.svg',
        },
        {
          id: 3,
          name: 'Classification',
          description: 'For simply differentiating between multiple classes (1 per image). Separate the images into folders named like the corresponding class.',
          icon: 'icon-testmode-classification.svg',
        },
        {
          id: 5,
          name: 'Binary',
          description: 'For 1 binary decision test case per image. Multiple classes can be categorized as "Ok" or "Nok" (separated in two folders). For example to check whether an object X exists in the image.',
          icon: 'icon-testmode-binary.svg',
        },
        {
          id: 2,
          name: 'Labels',
          description: 'If your test data is already labelled, you can use this mode to get additional information about the intersections of labels and results. Every image needs a corresponding json label for this mode.',
          icon: 'icon-testmode-labels.svg',
        },
        {
          id: 4,
          name: 'Positions',
          description: 'If you expect multiple detections at different positions within 1 image, use this mode. Separate the images into pattern folders which represent what kind of detection pattern you expect.',
          icon: 'icon-testmode-positions.svg',
        },
        {
          id: 6,
          name: 'Defects',
          description: 'If your expected result is no detections and only classes for defects exist, you should use this mode. Separate your images in two folders (\'ok\' for no defects and \'nok\' for images with defects).',
          icon: 'icon-testmode-defects.svg',
        },
      ],
    };
  },
  props: {
    selectedId: {
      type: Number,
      default: 1,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.fetchModes();
  },
  methods: {
    hideMode(modeId) {
      if (process.env.NODE_ENV === 'production') {
        return (modeId === 2 || modeId === 4 || modeId === 6);
        // eslint-disable-next-line no-else-return
      } else {
        return false;
      }
    },
    fetchModes() {
      this.syncData();
      this.loading = false;
    },
    syncData() {
      const selectedMode = this.modes.find((x) => x.id === this.selectedId);
      if (selectedMode) {
        this.activate(selectedMode);
      }
    },
    activate(el) {
      this.active_el = el;
      this.$emit('input', this.active_el);
    },
  },
  computed: {
    itemsWithIconUrl() {
      return this.modes.map(
        (item) => ({
          ...item,
          // eslint-disable-next-line global-require,import/no-dynamic-require
          iconUrl: require(`@/assets/icons/modes/${item.icon}`),
        }),
      );
    },
  },
};
</script>
<style scoped>
.active {
  border: #CE0F69 solid 3px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.testingform__modetype {
  cursor: pointer;
}
</style>
