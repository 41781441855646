import { render, staticRenderFns } from "./Testings.vue?vue&type=template&id=05833732&scoped=true"
import script from "./Testings.vue?vue&type=script&lang=js"
export * from "./Testings.vue?vue&type=script&lang=js"
import style0 from "./Testings.vue?vue&type=style&index=0&id=05833732&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05833732",
  null
  
)

export default component.exports