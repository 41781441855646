function editTesting(testing) {
  console.log('editTesting testing', testing);
  const { id } = testing.testing;
  const data = {
    title: testing.title,
    description: testing.description,
    internal_identifier: testing.internalIdentifier,
    training_id: testing.training.id,
    dataset_directory: testing.datasetDirectory,
    dataset_classes: testing.datasetClasses,
    mode: testing.testingMode.name.toLowerCase(),
    patterns: testing.patterns,
    minimum_confidence: testing.minimumConfidence,
    minimum_IOU: testing.minimumIOU,
    minimum_class_distance: testing.minimumClassDistance,
    maximum_location_distance: testing.maximumLocationDistance,
  };

  return window.$http({
    url: `/api/v1/testing/${id}`,
    method: 'put',
    data,
  });
}

export default editTesting;
