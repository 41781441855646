<template>
  <div class="testings-page">
    <v-container class="mt-5 mb-15 testings-header">
      <v-row class="justify-start">
        <v-col cols="12" sm="6" md="6" lg="3">
          <v-card class="testings-header__new"
                  :disabled="credit < 0 && usedMinutes > minutesToSpend">
            <v-dialog
              v-model="dialog"
              max-width="800px"
              @keydown.esc="closeDialog"
              @click:outside="closeDialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="new" @click="resetTesting()"
                       >
                  <v-icon left>mdi-plus-circle-outline</v-icon>
                  <div class="btn-text">New Testing</div>
                </v-btn>
              </template>
              <v-card v-if="!isTestingSubmitted">
                  <template v-if="loadingFileListing">
                    <div class="overlay-loading"></div>
                    <div class="icon-loading">
                      <v-progress-circular
                        indeterminate
                        size="25"
                        width="2"
                        color="primary"
                      ></v-progress-circular>
                    </div>
                  </template>
                <v-form
                  v-if="!mainFormSubmitButtonClicked"
                  @submit.prevent="onValidateForm"
                  ref="form"
                  class="testingform"
                >
                  <v-toolbar dense flat>
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row class="justify-start mt-0">
                      <v-col cols="10">
                        <h3>
                          Testmode<v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="tooltip"
                              right
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-help-circle
                            </v-icon>
                          </template>
                          <span>
                              Here you first select the test mode with
                              which your data is to
                              <br />
                              be tested. The title describes the generated
                              model type that will<br />
                              later be used for the evaluation by the AI. A
                              description of which<br />
                              model type is suitable for which application is
                              displayed below <br />
                              the selection. Please note that the selection can
                              have a considerable
                              <br />influence on the duration and the costs of a
                              testing.
                            </span>
                        </v-tooltip>
                        </h3>
                      </v-col>
                    </v-row>
                    <Modes
                      v-model="selectedMode"
                      :selectedId="this.selectedMode.id"
                      :isEditing="isEditing"
                      :key="componentKeySelectedMode"
                    />
                    <form-error :errors="formErrors.selectedMode" />
                    <v-row class="justify-start mt-5">
                      <v-col cols="10">
                        <h3>
                          Testing Ressources<v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="tooltip"
                              right
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-help-circle
                            </v-icon>
                          </template>
                          <span>
                              Select the machine and the testing folder for
                              which the testing<br />
                              is to be started. Only machines that have
                              previously been created<br />
                              via the settings are available for selection
                              here.<br />
                              The testing folder and its data must be located
                              in the File Manager<br />
                              under "ai-testing" of the machine's data
                              structure.<br />
                              The model on which the testing is to be executed is selected<br />
                              by entering the corresponding Training folder.
                            </span>
                        </v-tooltip>
                        </h3>
                      </v-col>
                    </v-row>
                    <div>
                      <Machines
                        v-model="selectedMachine"
                        :selected="selectedMachine.id"
                        :key="componentKeySelectedMachine"
                      />
                      <form-error :errors="formErrors.machine_id" />
                    </div>
                    <v-select
                      :disabled="isEmpty(selectedMachine)"
                      label="Select model (training folder)"
                      v-model="selectedTraining"
                      :items="trainings"
                      itemText="title"
                      itemValue="id"
                      @change="onTrainingChange"
                      :rules="$rules.required"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content class="notranslate" translate="no">
                          <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                    <form-error :errors="formErrors.selectedTraining" />
                    <v-select
                      :disabled="isEmpty(selectedTraining)"
                      label="Select test folder"
                      v-model="editedItem.datasetDirectory"
                      :items="currentDirListing"
                      itemText="name"
                      itemValue="name"
                      :rules="$rules.required"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content class="notranslate" translate="no">
                          <v-list-item-title v-text="item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                    <form-error :errors="formErrors.datasetDirectory" />
                    <v-row class="justify-start mt-5">
                      <v-col cols="10">
                        <h3>
                          Information<v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="tooltip"
                                right
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle
                              </v-icon>
                            </template>
                            <span>
                              Here you enter a meaningful title<br />
                              and a more detailed description of the test,
                              e.g. the features on which the selected model was trained.<br />
                              In addition, you can enter an internal identifier here,<br />
                              e.g. as for training, an individual order or product number,<br />
                              a company-internal project-specific designation or similar.
                            </span>
                          </v-tooltip>
                        </h3>
                      </v-col>
                    </v-row>
                    <v-text-field
                      label="Title"
                      v-model="editedItem.title"
                      :rules="[rules.minLength(1), rules.maxLength(128), rules.noSpecialCharacters()]"
                      class="mt-3"
                    ></v-text-field>
                    <form-error :errors="formErrors.title" />
                    <v-textarea
                      label="Description"
                      v-model="editedItem.description"
                      :rules="[rules.minLength(3), rules.maxLength(500)]"
                      rows="3"
                      class="mt-3"
                    ></v-textarea>
                    <form-error :errors="formErrors.description" />
                    <v-text-field
                      label="Internal identifier"
                      v-model="editedItem.internalIdentifier"
                      :rules="$rules.required"
                      class="mt-3"
                    ></v-text-field>
                    <form-error :errors="formErrors.internalIdentifier" />
                  </v-card-text>
                  <v-card-actions class="pr-4">
                    <v-spacer></v-spacer>
                    <v-btn type="submit" class="btn btn-radius-corner">
                      {{ mainFormSubmitButtonTitle }}</v-btn
                    >
                    <v-btn
                      @click="closeDialog"
                      class="btn btn-gray btn-radius-corner"
                    >
                      Cancel</v-btn
                    >
                  </v-card-actions>
                </v-form>
                <v-form
                  v-if="selectedMode.id !== 1 && mainFormSubmitButtonClicked && !secondFormSubmitButtonClicked"
                  @submit.prevent="onValidateForm"
                  ref="form"
                  class="testingform"
                >
                  <v-toolbar dense flat>
                    <v-toolbar-title>{{ dialogTitle }} ({{ selectedMode.name }})</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row class="justify-start mt-0">
                      <v-col cols="5">
                        <h4>
                          Minimum Confidence [%]
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="tooltip"
                                right
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle
                              </v-icon>
                            </template>
                            <span>
                              Determines the lower confidence threshold at which a prediction<br/>
                              made by the model is rejected.<br/>
                              A confidence lower than 50% should never be considered.
                            </span>
                          </v-tooltip>
                        </h4>
                      </v-col>
                      <v-col cols="3" style="text-align: left; padding-left: 0">
                        <v-btn
                          @click="setMinimumConfidence"
                          class="btn btn-secondary"
                        >
                          {{ testingConstants.defaultMinimumConfidence }} (default)
                        </v-btn>
                      </v-col>
                      <v-col cols="4" style="padding-left: 20px;">
                        <v-text-field
                          ref="minimumConfidenceTextField"
                          label="Free range (50 - 99)"
                          v-model="editedItem.minimumConfidence"
                          :rules="[rules.range(50, 99)]"
                        ></v-text-field>
                        <form-error :errors="formErrors.minimumConfidence" />
                      </v-col>
                    </v-row>
                    <v-row v-if="selectedMode.id === 2" class="justify-start">
                      <v-col cols="5">
                        <h4>
                          Minimum IoU [%]
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="tooltip"
                                right
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle
                              </v-icon>
                            </template>
                            <span>
                              Determines the lower intersection over union threshold at which a prediction<br/>
                              made by the model is rejected.<br/>
                              The intersection over union is 100% if the prediction is exactly equal to the<br/>
                              label and is 0% if the label and the prediction don't intersect at all.
                            </span>
                          </v-tooltip>
                        </h4>
                      </v-col>
                      <v-col cols="3" style="text-align: left; padding-left: 0">
                        <v-btn
                          @click="setMinimumIOU"
                          class="btn btn-secondary"
                        >
                          {{ testingConstants.defaultMinimumIOU }} (default)
                        </v-btn>
                      </v-col>
                      <v-col cols="4" style="padding-left: 20px;">
                        <v-text-field
                          ref="minimumIOUTextField"
                          label="Free range (10 - 99)"
                          v-model="editedItem.minimumIOU"
                          :rules="[rules.range(10, 99)]"
                        ></v-text-field>
                        <form-error :errors="formErrors.minimumIOU" />
                      </v-col>
                    </v-row>
                    <v-row v-if="selectedMode.id === 4" class="justify-start">
                      <v-col cols="5">
                        <h4>
                          Maximum Location Distance [px]
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="tooltip"
                                right
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle
                              </v-icon>
                            </template>
                            <span>
                              Determines the upper location distance threshold at which a<br/>
                              prediction made by the model is rejected.<br/>
                              Calculates the distance between the center of the label and<br/>
                              the center of the prediction in pixel.
                            </span>
                          </v-tooltip>
                        </h4>
                      </v-col>
                      <v-col cols="3" style="text-align: left; padding-left: 0">
                        <v-btn
                          @click="setMaximumLocationDistance"
                          class="btn btn-secondary"
                        >
                          {{ testingConstants.defaultMaximumLocationDistance }} (default)
                        </v-btn>
                      </v-col>
                      <v-col cols="4" style="padding-left: 20px;">
                        <v-text-field
                          ref="maximumLocationDistanceTextField"
                          label="Free range (1 - 999)"
                          v-model="editedItem.maximumLocationDistance"
                          :rules="[rules.range(1, 999)]"
                        ></v-text-field>
                        <form-error :errors="formErrors.maximumLocationDistance" />
                      </v-col>
                    </v-row>
                    <v-row v-if="selectedMode.id !== 6" class="justify-start">
                      <v-col cols="5">
                        <h4>
                          Minimum Class Distance [%]
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="tooltip"
                                right
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle
                              </v-icon>
                            </template>
                            <span>
                              Determines the lower class distance threshold at which a<br/>
                              prediction made by the model is rejected.<br/>
                              Compares the confidence distance between the best class<br/>
                              to the second best other class.
                            </span>
                          </v-tooltip>
                        </h4>
                      </v-col>
                      <v-col cols="3" style="text-align: left; padding-left: 0">
                        <v-btn
                          @click="setMinimumClassDistance"
                          class="btn btn-secondary"
                        >
                          {{ testingConstants.defaultMinimumClassDistance }} (default)
                        </v-btn>
                      </v-col>
                      <v-col cols="4" style="padding-left: 20px;">
                        <v-text-field
                          ref="minimumClassDistanceTextField"
                          label="Free range (1 - 90)"
                          v-model="editedItem.minimumClassDistance"
                          :rules="[rules.range(1, 90)]"
                        ></v-text-field>
                        <form-error :errors="formErrors.minimumClassDistance" />
                      </v-col>
                    </v-row>
                    <v-row v-if="selectedMode.id === 3" class="justify-start mt-3">
                      <v-col cols="12">
                        <h3>
                          List of Classes
                        </h3>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-list
                          class="pl-3 pr-3 pt-3 pb-3"
                          style="max-height: 313px;"
                          >
                          <template v-if="loadingDatasetClasses">
                            <div class="icon-loading">
                              <v-progress-circular
                                indeterminate
                                size="25"
                                width="2"
                                color="primary"
                              ></v-progress-circular>
                            </div>
                          </template>
                          <v-list-item
                            v-for="item in editedItem.datasetClasses"
                            :title="item.Name"
                            :key="item.key"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.Name }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="pt-8 pr-4">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="backToFirstForm"
                      class="btn btn-radius-corner"
                    >
                      Back</v-btn
                    >
                    <v-btn
                      type="submit"
                      class="btn btn-radius-corner"
                      :disabled="selectedMode.id === 3 && (editedItem.datasetClasses.length === 0 || loadingDatasetClasses)"
                    >
                      {{ secondFormSubmitButtonTitle }}</v-btn
                    >
                    <v-btn
                      @click="closeDialog"
                      class="btn btn-gray btn-radius-corner"
                    >
                      Cancel</v-btn
                    >
                  </v-card-actions>
                </v-form>
                <v-form
                  v-if="(selectedMode.id === 4 || selectedMode.id === 5)
                  && secondFormSubmitButtonClicked
                  && !addNewPatternButtonClicked
                  && !addNewPositionButtonClicked"
                  @submit.prevent="onValidateForm"
                  ref="form"
                  class="testingform"
                >
                  <v-toolbar dense flat>
                    <v-toolbar-title>{{ dialogTitle }} ({{ selectedMode.name }})</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row v-if="selectedMode.id === 5" class="justify-start mt-0">
                      <v-col cols="12">
                        <h3>
                          List of Classes
                        </h3>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-list
                          class="pl-3 pr-3 pt-3 pb-3"
                          style="max-height: 452px;"
                          >
                          <template v-if="loadingDatasetClasses">
                            <div class="icon-loading">
                              <v-progress-circular
                                indeterminate
                                size="25"
                                width="2"
                                color="primary"
                              ></v-progress-circular>
                            </div>
                          </template>
                          <v-list-item
                            v-for="item in editedItem.datasetClasses"
                            :title="item.Name"
                            :key="item.id"
                            style="border-bottom: 1px solid #e8e8e8"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.Name }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch
                                dense
                                style="padding-right: 6px;"
                                v-model="item.TypeOK"
                                title=""
                              >
                                <template v-slot:label>
                                  <span style="font-size: 15px; padding-left: 8px;">OK</span>
                                </template>
                              </v-switch>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                    <v-row v-if="selectedMode.id === 4" class="justify-start mt-0">
                      <v-col cols="12" class="text-right pt-0">
                        <v-btn
                          @click="openPatternForm"
                          class="btn btn-radius-corner"
                        >
                          <v-icon left>mdi-plus</v-icon>
                          Add new pattern
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <h3>
                          List of Patterns
                        </h3>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-list
                          class="pl-3 pr-3 pt-3 pb-3"
                          style="max-height: 440px;"
                          >
                          <template v-if="editedItem.patterns.length === 0">
                            <div class="text-center pt-6">No Patterns</div>
                          </template>
                          <v-list-item
                            v-for="(item, index) in editedItem.patterns"
                            :title="item.Name"
                            :key="index"
                            style="border-bottom: 1px solid #e8e8e8"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.Name }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <div class="d-flex flex-nowrap">
                                <v-btn title="Edit" icon @click="onEditPattern(item, index)">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn title="Delete" icon @click="onDeletePattern(index)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="pt-8 pr-4">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="backToSecondForm"
                      class="btn btn-radius-corner"
                    >
                      Back</v-btn
                    >
                    <v-btn
                      type="submit"
                      class="btn btn-radius-corner"
                      :disabled="(selectedMode.id === 4 && editedItem.patterns.length === 0)
                        || (selectedMode.id === 5 && (editedItem.datasetClasses.length === 0 || loadingDatasetClasses))"
                    >
                      {{ thirdFormSubmitButtonTitle }}</v-btn
                    >
                    <v-btn
                      @click="closeDialog"
                      class="btn btn-gray btn-radius-corner"
                    >
                      Cancel</v-btn
                    >
                  </v-card-actions>
                </v-form>
                <v-form
                  v-if="selectedMode.id === 4 && addNewPatternButtonClicked && !addNewPositionButtonClicked"
                  @submit.prevent="onCreatePattern"
                  ref="form"
                  class="testingform"
                >
                  <v-toolbar dense flat>
                    <v-toolbar-title>{{ patternFormTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row class="justify-start mt-0">
                      <v-col cols="12">
                        <v-text-field
                          label="Name"
                          v-model="pattern.Name"
                          :rules="[rules.minLength(1), rules.maxLength(128)]"
                        ></v-text-field>
                        <form-error :errors="formErrors.Name" />
                      </v-col>
                    </v-row>
                    <v-row class="justify-start mt-3">
                      <v-col cols="12" class="text-right">
                        <v-btn
                          @click="openPositionForm"
                          class="btn btn-radius-corner"
                        >
                          <v-icon left>mdi-plus</v-icon>
                          Add new position
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <h3>
                          List of Positions
                        </h3>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-list
                          class="pl-3 pr-3 pt-3 pb-3"
                          style="max-height: 355px;"
                          >
                          <template v-if="positions.length === 0">
                            <div class="text-center pt-6">No Positions</div>
                          </template>
                          <v-list-item
                            v-for="(item, index) in positions"
                            :key="index"
                            style="border-bottom: 1px solid #e8e8e8"
                          >
                            <v-list-item-content style="padding-top: 5px; padding-bottom: 5px;">
                              <v-list-item-title style="padding-bottom: 7px;">
                                {{ index }}
                              </v-list-item-title>
                              <v-list-item-subtitle style="margin: 0;" v-if="item.DatasetClass.Name !== undefined">
                                Class: {{ item.DatasetClass.Name }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle style="margin: 0;" v-else>
                                Class: {{ item.DatasetClass }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                Position_X: {{ item.PosX }},&nbsp; Position_Y: {{ item.PosY }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <div class="d-flex flex-nowrap">
                                <v-btn title="Edit" icon @click="onEditPosition(item, index)">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn title="Delete" icon @click="onDeletePosition(index)">
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="pt-8 pr-4">
                    <v-spacer></v-spacer>
                    <v-btn
                      type="submit"
                      class="btn btn-radius-corner"
                      :disabled="positions.length === 0"
                    >
                      {{ patternFormSubmitButtonTitle }}</v-btn
                    >
                    <v-btn
                      @click="backToThirdForm"
                      class="btn btn-gray btn-radius-corner"
                    >
                      Cancel</v-btn
                    >
                  </v-card-actions>
                </v-form>
                <v-form
                  v-if="selectedMode.id === 4 && addNewPatternButtonClicked && addNewPositionButtonClicked"
                  @submit.prevent="onCreatePosition"
                  ref="form"
                  class="testingform"
                >
                  <v-toolbar dense flat>
                    <v-toolbar-title>{{ positionFormTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row class="justify-start mt-5">
                      <v-col cols="12">
                        <h3>
                          Class
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="tooltip"
                                right
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle
                              </v-icon>
                            </template>
                            <span>
                              Select which class should be detected at this position.
                            </span>
                          </v-tooltip>
                        </h3>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          label="Select class (from Labelmap)"
                          v-model="position.DatasetClass"
                          :items="editedItem.datasetClasses"
                          itemText="Name"
                          itemValue="Name"
                          @change="onClassChange"
                          :rules="$rules.required"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content class="notranslate" translate="no">
                              <v-list-item-title v-text="item.Name"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                        <form-error :errors="formErrors.DatasetClass" />
                      </v-col>
                    </v-row>
                    <v-row class="justify-start">
                      <v-col cols="4">
                        <h3>
                          Position
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="tooltip"
                                right
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle
                              </v-icon>
                            </template>
                            <span>
                              Enter the X/Y coordinates for the expected prediction (center) within the image.<br/>
                              Remember that the upper left corner of the image is (0,0).
                            </span>
                          </v-tooltip>
                        </h3>
                      </v-col>
                      <v-col cols="4" style="padding-right: 20px;">
                        <h3>
                          X
                        </h3>
                        <v-text-field
                          class="mt-2"
                          label="Free range (0 - 9999)"
                          v-model="position.PosX"
                          :rules="[rules.range(0, 9999), rules.textFieldRequired()]"
                        ></v-text-field>
                        <form-error :errors="formErrors.PosX" />
                      </v-col>
                      <v-col cols="4" style="padding-left: 20px;">
                        <h3>
                          Y
                        </h3>
                        <v-text-field
                          class="mt-2"
                          label="Free range (0 - 9999)"
                          v-model="position.PosY"
                          :rules="[rules.range(0, 9999), rules.textFieldRequired()]"
                        ></v-text-field>
                        <form-error :errors="formErrors.PosY" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="pt-5 pr-4">
                    <v-spacer></v-spacer>
                    <v-btn
                      type="submit"
                      class="btn btn-radius-corner"
                    >
                      {{ positionFormSubmitButtonTitle }}</v-btn
                    >
                    <v-btn
                      @click="backToPatternForm"
                      class="btn btn-gray btn-radius-corner"
                    >
                      Cancel</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="ps-sm-6">
          <v-card
            class="
              testings-header__counter
              dashboard__card-placeholder
              v-card v-sheet
            "
          >
            <div class="label">Euro</div>
            <div class="value" v-if="credit === null">
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
              ></v-progress-circular>
            </div>
            <div class="value" v-else>{{ credit }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="ps-lg-6">
          <v-card
            class="
              testings-header__counter
              dashboard__card-placeholder
              v-card v-sheet
            "
          >
            <div class="label">Minutes</div>
            <div class="value" v-if="usedMinutes === null">
                <v-progress-circular
                  indeterminate
                  size="20"
                  width="2"
                ></v-progress-circular>
              </div>
            <div class="value" v-else>{{ usedMinutes }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="ps-sm-6">
          <v-card
            class="
              testings-header__counter
              dashboard__card-placeholder
              v-card v-sheet
            "
          >
            <div class="label">Completed</div>
            <div class="value" v-if="finishedTestings === null">
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
              ></v-progress-circular>
            </div>
            <div class="value" v-else>{{ finishedTestings }}</div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="content-header-section pt-15 pb-15">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <div class="d-flex justify-space-between">
            <div class="d-flex">
              <div class="mr-3">
                <v-select
                  clearable
                  v-model="selectedStatus"
                  :items="allStatus"
                  @change="onFilterChange"
                  item-text="name"
                  item-value="value"
                  label="Status"
                  dense
                  class="status-select"
                  v-if="testings"
                  :disabled="loading"
                ></v-select>
              </div>
              <div class="mr-3">
                <v-select
                  clearable
                  v-model="filterSelectedMachine"
                  :items="machines"
                  @change="onFilterChange"
                  item-text="title"
                  item-value="id"
                  label="Machine"
                  dense
                  class="select-machine"
                  v-if="machines"
                  :disabled="loading"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content class="notranslate" translate="no">
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
              <div>
                <v-select
                  clearable
                  v-model="filterSelectedUser"
                  :items="users"
                  @change="onFilterChange"
                  :item-text="item => item.firstname + ' ' + item.lastname"
                  item-value="id"
                  label="User"
                  dense
                  class="select-user"
                  v-if="users"
                  :disabled="loading"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content class="notranslate" translate="no">
                      <v-list-item-title v-text="item.firstname + ' ' + item.lastname"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
            </div>
            <div>
              <v-btn
                small
                text
                @click="resetFilter()"
                :disabled="filterSelectedMachine === 'all' && selectedStatus === 'all'
                  && filterSelectedUser === 'all'"
              >Clear filters</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-container
        class="content-header-section pt-15 pb-15 text-center"
        v-if="loading"
      >
        <v-progress-circular
          indeterminate
          size="100"
          color="black"
        ></v-progress-circular>
      </v-container>
      <v-row v-else>
        <v-col cols="12" class="testings">
          <v-expansion-panels multiple v-model="panel" v-if="testings">
            <div
              class="testings__item"
              v-for="(testing, index) in testings"
              :key="index"
            >
              <Testing
                :testing="testing"
                :key="componentKeyTestings"
                :id="index"
                :exceeded_resources="exceeded_resources"
                @start="onStart"
                @delete="onDelete"
                @cancel="onCancel"
                @edit="onEdit"
                @clone="onClone"
                @retry="retryValidation"
                @download="downloadResultTesting"
              />
            </div>
          </v-expansion-panels>
          <div  v-if="testings.length === 0 && loading === false"
            class="col-2 mx-auto">
            <span>{{ $t('common.no-data-available') }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="content-header-section pt-0 pb-15">
      <v-pagination
        v-if="totalPages > 1"
        v-model="page"
        :length="totalPages"
        :total-visible="7"
        class="justify-center"
        @input="closeAllPanels"
      ></v-pagination>
    </v-container>
    <dialog-confirm  ref="confirm" />
  </div>
</template>

<script>
import getTestings from '@/api/user/getTestings';
import validateDataset from '@/api/user/validateDataset';
import abortTesting from '@/api/user/abortTesting';
import editTesting from '@/api/user/editTesting';
import getAccountBalance from '@/api/customer/getAccountBalance';
import calculateMinutes from '@/api/user/calculateMinutes';
import DialogConfirm from '@/components/DialogConfirm.vue';
import FormErrorMixin from '@/mixins/FormErrorMixin';
import FormError from '@/components/_common/FormError.vue';
import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin';
// import getCountImages from '@/api/filemanager/getCountImages';
import getTrainings from '@/api/user/getTrainings';
import Modes from '@/components/testings/new/Modes.vue';
import createTesting from '@/api/user/createTesting';
import startTesting from '@/api/user/startTesting';
import deleteTesting from '@/api/user/deleteTesting';
import testingFolderList from '@/api/user/testingFolderList';
import getTrainingsOfMachine from '@/api/user/getTrainingsOfMachine';
import getPresignedUrl from '@/api/user/getPresignedUrl';
import getMachines from '@/api/user/getMachines';
import getDatasetClasses from '@/api/user/getDatasetClasses';
import Testing from './Testing.vue';
import Machines from './new/Machines.vue';

export default {
  name: 'Testings',
  components: {
    Testing,
    DialogConfirm,
    Modes,
    Machines,
    FormError,
  },
  mixins: [FormErrorMixin, FormValidationRulesMixin],
  computed: {
    isEditing() {
      return this.editedIndex !== -1;
    },
    dialogTitle() {
      return this.editedIndex === -1 ? 'New Testing' : 'Edit testing';
    },
    mainFormSubmitButtonTitle() {
      if (this.selectedMode.id === 1) {
        return this.editedIndex === -1 ? 'Create testing' : 'Update testing';
      }
      return 'Next';
    },
    secondFormSubmitButtonTitle() {
      if (this.selectedMode.id === 2 || this.selectedMode.id === 3 || this.selectedMode.id === 6) {
        return this.editedIndex === -1 ? 'Create testing' : 'Update testing';
      }
      return 'Next';
    },
    thirdFormSubmitButtonTitle() {
      return this.editedIndex === -1 ? 'Create testing' : 'Update testing';
    },
    positionFormTitle() {
      return this.editedPositionIndex === -1 ? 'New Position' : 'Edit position';
    },
    positionFormSubmitButtonTitle() {
      return this.editedPositionIndex === -1 ? 'Create' : 'Update';
    },
    patternFormTitle() {
      return this.editedPatternIndex === -1 ? 'New Pattern' : 'Edit pattern';
    },
    patternFormSubmitButtonTitle() {
      return this.editedPatternIndex === -1 ? 'Create' : 'Update';
    },
  },
  data() {
    return {
      loading: true,
      page: 1,
      totalItems: 0,
      totalPages: 1,
      itemsPerPage: 30,
      interval: '',
      intervalUser: '',
      dialog: false,
      editedIndex: -1,
      editedItem: {
        title: '',
        description: '',
        internalIdentifier: '',
        datasetDirectory: '',
        datasetClasses: [],
        testingMode: Modes.data().modes[0],
        machineId: 0,
        countOfImages: 1,
        minimumConfidence: 0,
        minimumIOU: 0,
        minimumClassDistance: 0,
        maximumLocationDistance: 0,
        patterns: [],
        trainings: [],
        training: {},
        testing: {},
      },
      defaultItem: {
        title: '',
        description: '',
        internalIdentifier: '',
        datasetDirectory: '',
        datasetClasses: [],
        testingMode: Modes.data().modes[0],
        machineId: 0,
        selectedMachine: {},
        countOfImages: 1,
        minimumConfidence: 0,
        minimumIOU: 0,
        minimumClassDistance: 0,
        maximumLocationDistance: 0,
        patterns: [],
        trainings: [],
        training: {},
        testing: {},
      },
      componentKeyTestings: 0,
      componentKeySelectedMode: 1000,
      componentKeySelectedMachine: 2000,
      currentCompany: null,
      loadingUser: true,
      testings: [],
      trainings: [],
      credit: null,
      usedMinutes: null,
      minutesToSpend: null,
      finishedTestings: null,
      exceeded_resources: false,
      selectedMode: Modes.data().modes[0],
      selectedMachine: {},
      selectedTrainingId: null,
      selectedTraining: {},
      loadingFileListing: false,
      currentDirListing: [],
      bearer: '',
      apiUrl: process.env.VUE_APP_ROOT_API
        ? process.env.VUE_APP_ROOT_API.trim().replace(/\/+$/, '')
        : '',
      panel: [],
      date: new Date(),
      networkConstants: {
        fast: 0.0033333,
        balanced: 0.0033333,
        precise: 0.0108,
        segmentation: 0.015,
      },
      selectedStatus: 'all',
      allStatus: [
        { name: 'Aborted', value: 'aborted' },
        { name: 'Created', value: 'created' },
        { name: 'Running', value: 'running' },
        { name: 'Finished', value: 'completed' },
      ],
      machines: [],
      filterSelectedMachine: 'all',
      users: [],
      filterSelectedUser: 'all',
      mainFormSubmitButtonClicked: false,
      secondFormBackButtonClicked: false,
      secondFormSubmitButtonClicked: false,
      thirdFormSubmitButtonClicked: false,
      addNewPatternButtonClicked: false,
      addNewPositionButtonClicked: false,
      patternFormBackButtonClicked: false,
      loadingDatasetClasses: false,
      testingConstants: {
        defaultMinimumConfidence: 95,
        defaultMinimumIOU: 70,
        defaultMinimumClassDistance: 50,
        defaultMaximumLocationDistance: 50,
      },
      pattern: {},
      position: {},
      positions: [],
      patternsBeforeEdit: [],
      editedPositionIndex: -1,
      editedPatternIndex: -1,
      isTestingSubmitted: false,
    };
  },
  created() {
    this.getMachines();
    this.fetchTestings();
    this.calcFinishedTestings();
    const bearer = this.$store.getters['user/token'];
    this.bearer = bearer.replace('bearer ', '');
    if (this.$route.query.trainingData) {
      const trainingData = JSON.parse(this.$route.query.trainingData);
      console.log('trainingData', trainingData);
      this.createFromTraining(trainingData);
      this.dialog = true;
    }
    this.fetchUsedMinutes();
  },
  mounted() {
    this.interval = setInterval(() => {
      this.fetchTestings();
      this.calcFinishedTestings();
    }, 120000);
    this.getCredit();
    this.intervalUser = setInterval(() => {
      this.checkUserApiRequestDone();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.intervalUser);
  },
  methods: {
    resetFilter() {
      this.selectedStatus = 'all';
      this.filterSelectedMachine = 'all';
      this.filterSelectedUser = 'all';
      this.fetchTestings();
    },
    async getCredit() {
      const creditRes = await getAccountBalance();
      this.credit = creditRes.data.account_balance;
    },
    forceRerenderTestings() {
      this.componentKeyTestings += 1;
    },
    resetTesting() {
      // eslint-disable-next-line prefer-destructuring
      this.selectedMode = Modes.data().modes[0];
      this.selectedMachine = {};
      this.componentKeySelectedMode = 1000;
    },
    async getMachines() {
      await getMachines()
        .then((response) => {
          this.machines = response.data;
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.loadingFileListing = false;
        });
    },
    async fetchTestings() {
      this.loading = true;
      this.testings = [];
      const start = this.itemsPerPage * (this.page - 1);
      const state = this.selectedStatus === 'all' ? undefined : this.selectedStatus;
      const machineFilter = this.filterSelectedMachine === 'all' ? undefined : this.filterSelectedMachine;
      const userFilter = this.filterSelectedUser === 'all' ? undefined : this.filterSelectedUser;
      await getTestings(start, state, machineFilter, userFilter)
        .then((response) => {
          this.testings = response.data.data;
          this.totalItems = response.data.total;
          this.totalPages = Math.ceil(response.data.total / this.itemsPerPage);
          this.users = response.data.creators;
        })
        .finally(() => {
          this.loading = false;
          this.forceRerenderTestings();
        });
    },
    async fetchTrainingsOfMachine(machineId) {
      try {
        return getTrainingsOfMachine('completed', machineId)
          .then((response) => {
            this.trainings = response.data.data;
            console.log('this.trainings', this.trainings);
            return response.data.data;
          });
      } catch (error) {
        console.log('error', error);
        return [];
      }
    },
    async createFromTraining(trainingData) {
      const prefix = 'Test: ';

      this.loadingFileListing = true;
      console.log('createFromTraining: ', trainingData);
      this.editedItem = {
        testingMode: this.selectedMode,
        title: prefix + trainingData.title,
        description: prefix + trainingData.description,
        internalIdentifier: trainingData.internal_identifier,
        machineId: trainingData.machine.id,
        training: trainingData,
      };
      await this.fetchFoldersOfMachine(trainingData.machine.id);
      try {
        getTrainingsOfMachine('completed', trainingData.machine.id).then((res) => {
          console.log('res', res);
          this.trainings = res.data.data;
          this.editedItem.trainings = res.data.data;
          this.selectedMachine = { ...trainingData.machine };
          this.selectedTraining = this.trainings.find(
            (training) => training.id === trainingData.id,
          );
          this.selectedTrainingId = trainingData.id;
          this.loadingFileListing = false;
        });
      } catch (error) {
        console.log('error', error);
        this.loadingFileListing = false;
      }
    },
    async fetchTrainings() {
      getTrainings(0, 'completed')
        .then((response) => {
          this.trainings = response.data.data;
          console.log('this.trainings', this.trainings);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    async fetchFoldersOfMachine(machineId) {
      this.loadingFileListing = true;
      try {
        const response = await testingFolderList(machineId);
        this.currentDirListing = response.data;
      } catch (error) {
        console.log('error', error);
        this.currentDirListing = [];
      } finally {
        this.loadingFileListing = false;
      }
    },
    async fetchDatasetClasses(machineId, datasetDirectory) {
      this.loadingDatasetClasses = true;
      try {
        await getDatasetClasses(machineId, datasetDirectory)
          .then((response) => {
            this.editedItem.datasetClasses = response.data;
            for (let i = 0; i < this.editedItem.datasetClasses.length; i += 1) {
              this.editedItem.datasetClasses[i].TypeOK = true;
            }
          });
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loadingDatasetClasses = false;
      }
    },
    calcFinishedTestings() {
      const filter = 'completed';
      getTestings(0, filter)
        .then((response) => {
          this.finishedTestings = response.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onDelete(testing) {
      console.log('onDelete', testing);
      this.$refs.confirm
        .open('Delete this testing', 'Do you really want to delete this testing?')
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch(
              'dialogloader/show',
              'The testing is being deleted ...',
            );
            deleteTesting(testing).finally(() => {
              this.fetchTestings();
              this.$store.dispatch('dialogloader/hide');
            });
          }
        });
    },
    onCancel(testing) {
      const testingTitle = testing.title ? `with the title "${testing.title}"` : '';
      this.$refs.confirm
        .open('Cancel this testing', `Are you sure to cancel the ongoing testing "${testingTitle}"?`)
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch(
              'dialogloader/show',
              'The testing is being canceled ...',
            );
            abortTesting(testing).finally(() => {
              this.fetchTestings();
              setTimeout(() => {
                this.$store.dispatch('dialogloader/hide');
              }, 1000);
            });
          }
        });
    },
    retryValidation(testing) {
      let validationDialogTitle = '';
      let validationDialogMessage = '';
      if (testing.state === 'not-validated') {
        validationDialogTitle = 'Validate this test';
        validationDialogMessage = 'Validation on this test?';
      } else {
        validationDialogTitle = 'Retry Validation this test';
        validationDialogMessage = 'Retry Validation on this test?';
      }
      this.$refs.confirm
        .open(
          validationDialogTitle,
          validationDialogMessage,
        )
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', 'Validating...');
            validateDataset('testing', testing.id)
              .then(() => {
                this.fetchTestings();
              })
              .finally(() => {
                this.$store.dispatch('dialogloader/hide');
              });
          }
        });
    },
    async onEdit(testing, index) {
      this.editedItem = { ...testing };
      this.editedItem.testingMode = testing.testing.mode;
      this.selectedMode = Modes.data().modes.find(
        (tMode) => tMode.name.toLowerCase() === this.editedItem.testingMode,
      );
      this.editedItem.machineId = testing.training.machine_id;
      this.selectedMachine = this.machines.find(
        (machine) => machine.id === testing.training.machine_id,
      );
      if (testing.testing.datasetDirectory) {
        this.editedItem.datasetDirectory = this.testing.datasetDirectory;
      } else {
        this.editedItem.datasetDirectory = testing.testing.dataset_directory;
      }
      this.selectedTrainingId = testing.training.id;
      this.editedIndex = index;
      this.editedItem.title = testing.testing.title;
      this.editedItem.description = testing.testing.description;
      this.editedItem.internalIdentifier = testing.testing.internal_identifier;
      if (this.selectedMode.id !== 1) {
        this.editedItem.patterns = testing.testing.data.patterns.map((patternItem) => ({
          Name: patternItem.pattern_folder,
          Positions: patternItem.pattern_positions.map((positionItem) => ({
            DatasetClass: positionItem.class_name,
            PosX: positionItem.position_x,
            PosY: positionItem.position_y,
          })),
        }));
        this.editedItem.minimumConfidence = testing.testing.data.target_confidence;
        this.editedItem.minimumIOU = testing.testing.data.target_iou;
        this.editedItem.minimumClassDistance = testing.testing.data.target_second_class_distance;
        this.editedItem.maximumLocationDistance = testing.testing.data.target_area_distance;
        this.editedItem.datasetClasses = testing.testing.data.dataset_classes.map((classItem) => ({
          Name: classItem.Name,
          Ignored: classItem.Ignored,
          TypeOK: classItem.TypeOK,
        }));
      }
      this.editedItem.training = testing.training;
      this.selectedTraining = testing.training;
      await this.fetchFoldersOfMachine(testing.training.machine_id);
      this.componentKeySelectedMode += 1;
      console.log('this.editedItem', this.editedItem);
      this.dialog = true;
    },
    onClone(testing) {
      this.$refs.confirm
        .open('Clone this testing', 'Confirm cloning this testing?')
        .then((confirm) => {
          if (confirm) {
            console.log('testing', testing);
            this.loadingFileListing = true;
            this.editedItem = { ...testing };
            this.fetchTrainingsOfMachine(testing.training.machine_id).then(
              (trainings) => {
                console.log('trainings', trainings);
                this.trainings = trainings;
                console.log('this.trainings', this.trainings);
                this.selectedTraining = this.trainings.find(
                  (training) => training.id === testing.training.id,
                );
                console.log('this.selectedTraining', this.selectedTraining);
                this.editedItem.testingMode = testing.testing.mode;
                this.selectedMode = Modes.data().modes.find(
                  (tMode) => tMode.name.toLowerCase() === this.editedItem.testingMode,
                );
                this.editedIndex = -1;
                this.editedItem.datasetDirectory = testing.testing.dataset_directory;
                this.editedItem.title = testing.testing.title;
                this.editedItem.description = testing.testing.description;
                this.editedItem.internalIdentifier = testing.testing.internal_identifier;
                this.editedItem.machineId = testing.training.machine_id;
                if (this.selectedMode.id === 1) {
                  this.editedItem.patterns = this.defaultItem.patterns;
                  this.editedItem.minimumConfidence = this.defaultItem.minimumConfidence;
                  this.editedItem.minimumIOU = this.defaultItem.minimumIOU;
                  this.editedItem.minimumClassDistance = this.defaultItem.minimumClassDistance;
                  this.editedItem.maximumLocationDistance = this.defaultItem.maximumLocationDistance;
                  this.editedItem.datasetClasses = this.defaultItem.datasetClasses;
                } else {
                  this.editedItem.patterns = testing.testing.data.patterns.map((patternItem) => ({
                    Name: patternItem.pattern_folder,
                    Positions: patternItem.pattern_positions.map((positionItem) => ({
                      DatasetClass: positionItem.class_name,
                      PosX: positionItem.position_x,
                      PosY: positionItem.position_y,
                    })),
                  }));
                  this.editedItem.minimumConfidence = testing.testing.data.target_confidence;
                  this.editedItem.minimumIOU = testing.testing.data.target_iou;
                  this.editedItem.minimumClassDistance = testing.testing.data.target_second_class_distance;
                  this.editedItem.maximumLocationDistance = testing.testing.data.target_area_distance;
                  if (this.selectedMode.id === 5) {
                    this.editedItem.datasetClasses = testing.testing.data.dataset_classes.map((classItem) => ({
                      Name: classItem.Name,
                      Ignored: classItem.Ignored,
                      TypeOK: classItem.TypeOK,
                    }));
                  } else {
                    this.editedItem.datasetClasses = testing.testing.data.dataset_classes.map((classItem) => ({
                      Name: classItem.Name,
                      Ignored: classItem.Ignored,
                      TypeOK: true,
                    }));
                  }
                }
                this.selectedMachine = this.machines.find(
                  (machine) => machine.id === testing.training.machine_id,
                );
                this.selectedTrainingId = testing.training.id;
                this.editedItem.training = this.selectedTraining;
                this.fetchFoldersOfMachine(testing.training.machine_id);
                this.componentKeySelectedMode += 1;
                this.componentKeySelectedMachine += 1;
                console.log('this.editedItem in clone', this.editedItem);
                this.dialog = true;
              },
            )
              .finally(() => {
                this.loadingFileListing = false;
              });
          }
        });
    },
    onStart(testing) {
      this.$refs.confirm
        .open('Start this testing', `Start testing "${testing.testing.title}"?`)
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch(
              'dialogloader/show',
              'The testing is being started ...',
            );
            startTesting(testing.testing).finally(() => {
              this.fetchTestings();
              this.$store.dispatch('dialogloader/hide');
            });
          }
        });
    },
    closeDialog() {
      if (this.$route.query.trainingData) {
        this.dialog = false;
        this.$router.push('testings');
      } else {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = { ...this.defaultItem };
          this.editedIndex = -1;
          if (this.$refs.form !== undefined) {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
          }
          this.trainings = [];
          this.formErrors = {};
          this.selectedTraining = {};
          this.selectedMachine = {};
          this.componentKeySelectedMode += 1;
          this.componentKeySelectedMachine += 1;
          this.mainFormSubmitButtonClicked = false;
          this.secondFormBackButtonClicked = false;
          this.secondFormSubmitButtonClicked = false;
          this.thirdFormSubmitButtonClicked = false;
          this.addNewPatternButtonClicked = false;
          this.addNewPositionButtonClicked = false;
          this.patternFormBackButtonClicked = false;
          this.pattern = {};
          this.position = {};
          this.positions = [];
          this.patternsBeforeEdit = [];
          this.editedPositionIndex = -1;
          this.editedPatternIndex = -1;
          this.isTestingSubmitted = false;
        });
      }
    },
    onValidateForm() {
      if (!this.$refs.form.validate()) return;
      this.editedItem.testingMode = this.selectedMode;
      if (this.selectedMode.id !== 1) {
        if (!this.mainFormSubmitButtonClicked && !this.secondFormBackButtonClicked) {
          this.mainFormSubmitButtonClicked = true;
          if (this.editedItem.datasetClasses.length === 0) {
            this.fetchDatasetClasses(this.editedItem.training.machine.id, this.editedItem.training.dataset_directory);
          }
        } else if (!this.mainFormSubmitButtonClicked && this.secondFormBackButtonClicked) {
          this.mainFormSubmitButtonClicked = false;
          this.secondFormBackButtonClicked = false;
        } else if (this.mainFormSubmitButtonClicked && !this.secondFormSubmitButtonClicked) {
          this.secondFormSubmitButtonClicked = true;
        } else if (this.thirdFormSubmitButtonClicked && this.patternFormBackButtonClicked) {
          this.patternFormBackButtonClicked = false;
          this.thirdFormSubmitButtonClicked = false;
        } else if (this.mainFormSubmitButtonClicked && this.secondFormSubmitButtonClicked && !this.thirdFormSubmitButtonClicked) {
          this.thirdFormSubmitButtonClicked = true;
        }
      } else {
        this.mainFormSubmitButtonClicked = true;
      }
      if (((this.selectedMode.id === 1) && this.mainFormSubmitButtonClicked)
        || ((this.selectedMode.id === 2 || this.selectedMode.id === 3 || this.selectedMode.id === 6) && this.secondFormSubmitButtonClicked)
        || ((this.selectedMode.id === 4 || this.selectedMode.id === 5) && this.thirdFormSubmitButtonClicked)) {
        this.isTestingSubmitted = true;
        this.saveItem();
      }
    },
    saveItem() {
      if (this.selectedMode.id !== 1) {
        this.validateParameters();
      }
      if (this.isEditing) {
        this.$store.dispatch('dialogloader/show', 'Editing in progress...');
        editTesting(this.editedItem)
          .then(() => {
            this.closeDialog();
            this.fetchTestings();
          })
          .finally(() => this.$store.dispatch('dialogloader/hide'));
      } else {
        this.$store.dispatch('dialogloader/show', 'Adding in progress...');
        createTesting(this.editedItem)
          .then(() => {
            this.resetTesting();
            this.closeDialog();
            this.fetchTestings();
            this.loading = false;
          })
          .finally(() => this.$store.dispatch('dialogloader/hide'));
      }
    },
    checkUserApiRequestDone() {
      this.currentCompany = this.$store.getters['user/company'];
      if (this.currentCompany) {
        if (this.currentCompany.pricing !== null) {
          this.minutesToSpend = this.currentCompany.pricing.training_minutes
          + this.currentCompany.pricing.more_minutes;
        }
        if (this.credit < 0 && (this.usedMinutes > this.minutesToSpend)) {
          this.exceeded_resources = true;
        } else {
          this.exceeded_resources = false;
        }
      }
    },
    onTrainingChange() {
      const originalSelectedTraining = this.selectedTraining;

      const foundTraining = this.trainings.find(
        (training) => training.id === originalSelectedTraining,
      );
      this.selectedTraining = foundTraining;
      console.log('onTrainingChange this.selectedTraining', this.selectedTraining);
      this.editedItem.training = this.selectedTraining;
      this.componentKeySelectedMode += 1;
      this.editedItem.datasetClasses = [];
    },
    isEmpty(obj) {
      return obj == null || (Object.keys(obj).length === 0 && obj.constructor === Object);
    },
    downloadResultTesting(testing) {
      this.$store.dispatch('dialogloader/show', 'Downloading in progress...');
      getPresignedUrl('testing', testing.testing, this.bearer, this.apiUrl)
        .then((response) => {
          const presignedUrl = response.data.presigned_url;
          const link = document.createElement('a');
          const disposition = response.headers['content-disposition'];
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          let filename = 'download';
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
          link.href = presignedUrl;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => console.log('ERROR: downloadResultTesting', error));
      this.$store.dispatch('dialogloader/hide');
    },
    fetchUsedMinutes() {
      calculateMinutes(this.date.getMonth() + 1, this.date.getFullYear())
        .then((response) => {
          this.usedMinutes = response.data.minutes;
        });
    },
    onFilterChange() {
      this.page = 1;
      this.fetchTestings();
    },
    closeAllPanels() {
      this.panel = [];
    },
    setMinimumConfidence() {
      this.editedItem.minimumConfidence = this.testingConstants.defaultMinimumConfidence;
      this.$refs.minimumConfidenceTextField.internalValue = this.editedItem.minimumConfidence;
    },
    setMinimumIOU() {
      this.editedItem.minimumIOU = this.testingConstants.defaultMinimumIOU;
      this.$refs.minimumIOUTextField.internalValue = this.editedItem.minimumIOU;
    },
    setMinimumClassDistance() {
      this.editedItem.minimumClassDistance = this.testingConstants.defaultMinimumClassDistance;
      this.$refs.minimumClassDistanceTextField.internalValue = this.editedItem.minimumClassDistance;
    },
    setMaximumLocationDistance() {
      this.editedItem.maximumLocationDistance = this.testingConstants.defaultMaximumLocationDistance;
      this.$refs.maximumLocationDistanceTextField.internalValue = this.editedItem.maximumLocationDistance;
    },
    backToFirstForm() {
      this.mainFormSubmitButtonClicked = false;
      this.secondFormBackButtonClicked = true;
    },
    backToSecondForm() {
      this.secondFormSubmitButtonClicked = false;
      this.thirdFormSubmitButtonClicked = false;
    },
    backToThirdForm() {
      this.addNewPatternButtonClicked = false;
      this.patternFormBackButtonClicked = true;
      this.pattern = {};
      this.positions = [];
      if (this.editedPatternIndex > -1) {
        this.editedItem.patterns = this.patternsBeforeEdit;
        this.editedPatternIndex = -1;
        this.patternsBeforeEdit = [];
      }
    },
    openPatternForm() {
      this.addNewPatternButtonClicked = true;
      this.thirdFormSubmitButtonClicked = true;
    },
    openPositionForm() {
      this.addNewPositionButtonClicked = true;
    },
    backToPatternForm() {
      this.addNewPositionButtonClicked = false;
      this.position = {};
      this.editedPositionIndex = -1;
    },
    onCreatePattern() {
      if (!this.$refs.form.validate()) return;
      if (this.editedPatternIndex > -1) {
        Object.assign(this.editedItem.patterns[this.editedPatternIndex], this.pattern);
        this.editedPatternIndex = -1;
      } else {
        this.editedItem.patterns.push({ Name: this.pattern.Name, Positions: this.positions });
      }
      this.addNewPatternButtonClicked = false;
      this.pattern = {};
      this.positions = [];
      this.patternsBeforeEdit = [];
    },
    onClassChange() {
      const originalSelectedClass = this.position.DatasetClass;
      const foundClass = this.editedItem.datasetClasses.find(
        (dClass) => dClass.Name === originalSelectedClass,
      );
      this.position.DatasetClass = foundClass;
    },
    onCreatePosition() {
      if (!this.$refs.form.validate()) return;
      if (this.editedPositionIndex > -1) {
        Object.assign(this.positions[this.editedPositionIndex], this.position);
        this.editedPositionIndex = -1;
      } else {
        this.positions.push(this.position);
      }
      this.addNewPositionButtonClicked = false;
      this.position = {};
    },
    onDeletePosition(index) {
      this.positions.splice(index, 1);
    },
    onEditPosition(item, index) {
      this.editedPositionIndex = index;
      this.position = { ...item };
      this.openPositionForm();
    },
    onDeletePattern(index) {
      this.editedItem.patterns.splice(index, 1);
      // There was a bug where deleted pattern remained in the list while editing/cloning a testing.
      this.addNewPatternButtonClicked = true;
      this.addNewPatternButtonClicked = false;
    },
    onEditPattern(item, index) {
      this.patternsBeforeEdit = JSON.parse(JSON.stringify(this.editedItem.patterns));
      this.editedPatternIndex = index;
      this.pattern = { ...item };
      this.positions = this.pattern.Positions;
      this.openPatternForm();
    },
    validateParameters() {
      if (this.selectedMode.id !== 2) {
        this.editedItem.minimumIOU = this.defaultItem.minimumIOU;
      }
      if (this.selectedMode.id === 6) {
        this.editedItem.minimumClassDistance = this.defaultItem.minimumClassDistance;
      }
      if (this.selectedMode.id !== 4) {
        this.editedItem.maximumLocationDistance = this.defaultItem.maximumLocationDistance;
      }
    },
  },
  watch: {
    trainings(newTrainings) {
      if (newTrainings && newTrainings.length > 0) {
        this.editedItem.trainings = newTrainings;
        this.trainings = newTrainings;
      }
    },
    dialog(newValue) {
      if (newValue === false) {
        this.closeDialog();
      }
    },
    selectedMachine(newVal) {
      if (newVal && newVal.id) {
        this.selectedMachine = newVal;
        this.fetchTrainingsOfMachine(newVal.id);
        this.fetchFoldersOfMachine(newVal.id);
      }
    },
    page: {
      deep: false,
      handler() {
        this.fetchTestings();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.testings-page {
  .status-toggle {
    height: auto !important;
    padding: 15px;
    column-gap: 10px;
    button.btn {
      border-radius: 4px;
      &:not(.btn-toggle--active) {
        .v-btn__content {
          color: #000 !important;
        }
      }
    }
  }
  hr.solid {
    border-style: solid;
  }
}

</style>
